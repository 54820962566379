import { useState } from 'react';
import QueryStringGlobal from '../../componets/queryStringGlobal';
import MainContainer from '../main-container';
import Form from './legalProcessForm';
import List from './legalProcessList';
import { rowsPerPageOptions } from '../../config';
import { momentZoneToDateAddDays, momentZoneToUnix } from '../../util/dateUtil';
import api from '../../services/api';

const LegalProcess: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const LegalProcessList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { LegalProcess, LegalProcessList };
