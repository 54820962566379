import {
    DownloadDone,
    NotInterested,
    CurrencyExchange,
    Paid,
    PriceCheck,
    EventNote,
    Close,
    RequestQuote,
    Print,
    InstallDesktop,
    ClearAll,
    ModeEdit,
    Delete,
    Search,
    AddCircle,
    Functions,
    SwipeRight,
    PermIdentityOutlined,
    LockOutlined,
    BusinessCenter,
    MoneyOff,
    MoneyOffCsred,
    Cable,
    PriceChange,
    PaidTwoTone,
    Archive,
    Business,
    Check,
} from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

export interface IButtonProps extends SvgIconProps {
    icon: any;
    width?: number;
    height?: number;
}

export const icons = {
    downloadDone: DownloadDone,
    notInterested: NotInterested,
    currencyExchange: CurrencyExchange,
    paid: Paid,
    priceCheck: PriceCheck,
    eventNote: EventNote,
    close: Close,
    requestQuote: RequestQuote,
    print: Print,
    installDesktop: InstallDesktop,
    clearAll: ClearAll,
    modeEdit: ModeEdit,
    delete: Delete,
    search: Search,
    addCircle: AddCircle,
    functions: Functions,
    swipeRight: SwipeRight,
    permIdentityOutlined: PermIdentityOutlined,
    lockOutlined: LockOutlined,
    businessCenter: BusinessCenter,
    moneyOff: MoneyOff,
    moneyOffCsred: MoneyOffCsred,
    cable: Cable,
    priceChange: PriceChange,
    paidTwoTone: PaidTwoTone,
    archive: Archive,
    business: Business,
    check: Check,
};

export const IconComponent = (properties: IButtonProps) => {
    return (
        <SvgIcon
            {...properties}
            component={properties.icon}
            disabled
            // sx={{ width: 24, height: 24 }}
        />
    );
};
