import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    TableCell,
    Typography,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState, FocusEvent } from 'react';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    optionsTypeNumberPhone,
    typeNumberPhoneFind,
} from '../../communs/enums/typeNumberPhone';
import { extraValidationCNPJ } from '../../communs/validations/cnpj';
import { extraValidationCPF } from '../../communs/validations/cpf';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { cepMask } from '../../componets/form/mask/cep';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { phoneMask } from '../../componets/form/mask/phone';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { findVal } from '../../util/findValueObjectByKey';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { objToQuery, queryToObj } from '../../util/query';
import { format } from 'date-fns';
import { FormInputProps } from '../../componets/form/formInterfaces';
import { ColumnDef } from '@tanstack/react-table';
import { IconComponent, icons } from '../../componets/icons';
import RenderForm from '../../componets/form/renderForm';
import { useWindowSize } from '../../util/responsiveness';

interface IFormInput {
    name: string;
    identification: string;
    birthDate: Date | null;
    formattedBirthDate: string | null;
    email: string;
    phones: IPhone[];
    publicPlace: string;
    number: string;
    addressComplement: string;
    district?: {
        id: string;
        description: string;
        cityIbgeId?: string;
        cityName?: string;
        cityAutocomplete?: {
            id: string;
            description: string;
        };
        stateIbgeId?: string;
        state?: string;
        stateName?: string;
        stateAutocomplete?: {
            id: string;
            description: string;
        };
        cep?: string;
    };
}

interface DataApiViaCep {
    cep: string;
    logradouro?: string;
    complemento?: string;
    bairro?: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
    erro?: boolean;
}

interface IPhone {
    phoneType: string;
    phoneDdd: string;
    phoneNumber: string;
    branch: string;
    id?: string;
    phoneTypeOption?: {
        id: string;
        description: string;
    };
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    setIndex: (index: number) => void;
    getValues: any;
    append: any;
    update: any;
    width: number;
    inputs: FormInputProps<IPhone>[];
    useFormItems: UseFormReturn<IPhone>;
}

interface Data {
    id: string;
    code: string;
    technician: {
        id: string;
        name: string;
    };
    dateEntry: Date;
    dateCompletion?: Date;
    modelBrand: {
        id: string;
        description: string;
        brand: {
            id: string;
            description: string;
        };
    };
    state: string;
    serviceValue?: number;
}

interface ModalDistrictProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    useFormItems: UseFormReturn<IFormInput>;
    width: number;
}

const defaultValues = {
    name: '',
    identification: '',
    email: '',
    publicPlace: '',
    number: '',
    birthDate: null,
    formattedBirthDate: null,
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { search } = useLocation();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalPhones, setOpenModalPhones] = useState(false);
    const [indexItem, setIndexItem] = useState<number>(-1);
    const [expanded, setExpanded] = React.useState<'panel-address' | false>(
        false,
    );
    const [expandedOrder, setExpandedOrder] = React.useState<
        'panel-order' | false
    >(false);
    const [optionsDistrict, setOptionsDistrict] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);
    const [optionsState, setOptionsState] = useState<
        { id: string; description: string }[]
    >([]);
    const [rowOrders, setRowOrders] = useState<Data[]>([]);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState('');
    const [queryLast, setQueryLast] = useState('');
    const [width] = useWindowSize();
    const [openModalDistrict, setOpenModalDistrict] = useState(false);

    const useFormInput = useForm<IFormInput>({
        defaultValues,
    });
    const { handleSubmit, control, reset, setValue, getValues } = useFormInput;

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'phones',
        'phonesId'
    >({
        control,
        name: 'phones',
        keyName: 'phonesId',
    });

    const useFormItems = useForm<IPhone>({
        defaultValues: {
            phoneType: undefined,
            phoneDdd: '047',
            phoneTypeOption: undefined,
            phoneNumber: '',
            branch: '',
        },
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`customer/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    useEffect(() => {
        if (optionsState.length === 0 && expanded === 'panel-address') {
            updateState();

            if (getValues('district.cityIbgeId')) {
                api.get(
                    `district?district.cityIbgeId=${getValues(
                        'district.cityIbgeId',
                    )}`,
                ).then(response => {
                    setOptionsDistrict(response.data.data);
                });
            }

            if (getValues('district.stateIbgeId')) {
                updateCity('' + getValues('district.stateIbgeId'));
            }
        }
    }, [expanded]);

    useEffect(() => {
        if (expandedOrder === 'panel-order' && params.id && query.length > 0) {
            getOrder();
        }
    }, [expandedOrder, query]);

    useEffect(() => {
        const getParam = getParams();

        if (getParam.expandedOrder === 'panel-order') {
            getParam.page = Number(page) + 1;
            getParam.state = '3,5,6,11,10';
            const queryTemp = objToQuery(getParam);
            // setQueryOutputProduct(queryTemp);
            history.push(`?${queryTemp}`);
        }
    }, [page]);

    const handleClick = (index: any) => {
        setIndexItem(index);
        setOpenModalPhones(true);
    };

    const columnsTable = React.useMemo<ColumnDef<IPhone>[]>(
        () => [
            {
                id: 'phoneType',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Tipo
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {
                                typeNumberPhoneFind(row.original.phoneType)
                                    ?.description
                            }
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'phoneDdd',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        DDD
                    </TableCell>
                ),
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() => handleClick(cell.row.id)}>
                            {cell.row.original.phoneDdd}
                        </TableCell>
                    );
                },
            },
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Número
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {phoneMask(row.original.phoneNumber, false)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Ramal
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {row.original?.branch || ''}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleClick(row.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                handleDeleteItemTable(row.id);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const onChangeInputWarrantyTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            setValueItems(`phoneType`, option?.id);
        }
    };

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'name',
            control: control,
            label: 'Nome',
            md: 6,
            xs: 12,
            rules: {
                required: true,
                minLength: 5,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo nome é obrigatório',
                },
                {
                    type: 'minLength',
                    message: 'O campo nome tem que ter mais do que 5 letras',
                },
            ],
        },
        {
            typeInput: 'text',
            name: 'identification',
            control: control,
            label: 'Identificação (CPF/CNPJ)',
            md: 6,
            xs: 12,
            mask: 'identification',
            rules: {
                required: true,
                validate: {
                    validateCNPJ: extraValidationCNPJ,
                    validateCPF: extraValidationCPF,
                },
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo identificação é obrigatório',
                },
                {
                    type: 'validateCNPJ',
                    message: 'CNPJ inválido',
                },
                {
                    type: 'validateCPF',
                    message: 'CPF inválido',
                },
            ],
        },
        {
            typeInput: 'date',
            name: 'birthDate',
            control: control,
            label: 'Data de nascimento',
            setValue: setValue,
            md: 6,
            xs: 12,
            placeholder: 'dd/mm/aaaa',
            inputFormat: 'dd/MM/yyyy',
            rules: {
                validate: (value: any) => {
                    if (value && String(value) === 'Invalid Date') {
                        return 'A data está em um formato inválido';
                    }
                },
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo data de nascimento é obrigatório',
                },
            ],
        },
        {
            typeInput: 'text',
            name: 'email',
            control: control,
            label: 'Email',
            md: 6,
            xs: 12,
            rules: {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
            messagesError: [
                {
                    type: 'pattern',
                    message: 'O email está inválido. Ex.: email@email.com',
                },
            ],
        },
        {
            typeInput: 'subForm',
            name: 'subForm',
            control,
            label: 'Telefones',
            md: 12,
            xs: 12,
            dataTable: {
                propsTable: {
                    columns: columnsTable,
                    data: fields,
                    minWidth: 600,
                },
                handleNewItemTable: () => {
                    setOpenModalPhones(true);
                    setIndexItem(-1);
                },
                inputs: [
                    {
                        typeInput: 'autocomplete',
                        name: 'phoneTypeOption',
                        control: controlItems,
                        label: 'Tipo',
                        loadingAutocomplete: false,
                        setValue: setValueItems,
                        options: optionsTypeNumberPhone(),
                        md: 5,
                        xs: 12,
                        rules: {
                            required: true,
                        },
                        messagesError: [
                            {
                                type: 'required',
                                message: 'O campo tipo é obrigatório',
                            },
                        ],
                        handleChange: onChangeInputWarrantyTime,
                    },
                    {
                        typeInput: 'text',
                        name: 'phoneDdd',
                        control: controlItems,
                        label: 'DDD',
                        md: 2,
                        xs: 12,
                        mask: 'number',
                        rules: {
                            required: true,
                        },
                        messagesError: [
                            {
                                type: 'required',
                                message: 'O campo DDD é obrigatório',
                            },
                        ],
                    },
                    {
                        typeInput: 'text',
                        name: 'phoneNumber',
                        control: controlItems,
                        label: 'Número',
                        md: 3,
                        xs: 12,
                        mask: 'phone',
                        rules: {
                            required: true,
                        },
                        messagesError: [
                            {
                                type: 'required',
                                message: 'O campo DDD é obrigatório',
                            },
                        ],
                    },
                    {
                        typeInput: 'text',
                        name: 'branch',
                        control: controlItems,
                        label: 'Ramal',
                        md: 2,
                        xs: 12,
                    },
                ] as FormInputProps<IPhone>[],
            },
        },
    ];

    const handleOnBlurCep = async (event: FocusEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value) {
            value = value.replace(/\D/g, '');
        }

        if (!value) {
            return;
        }

        setOpenLoading(true);
        try {
            const response: AxiosResponse<DataApiViaCep> = await axios.get(
                `https://viacep.com.br/ws/${value}/json/`,
            );

            const data = response.data;

            if (data.erro) {
                addToast({
                    type: 'warn',
                    title: message.error.selectOne,
                    description:
                        'O CEP deve estar inválido, verifique e digite novamente',
                });
            }

            if (data.bairro) {
                // API que busca district pela descrição, se não achar no banco ja cadastra ele e devolve, agora se achar, apenas devolve o registro
                try {
                    const resp = await api.get(
                        `district/exist-district?description=${data.bairro}&cityIbgeId=${data.ibge}&cityName=${data.localidade}&state=${data.uf}&cep=${data.cep}`,
                    );

                    setValue('district', {
                        id: resp.data.id,
                        description: resp.data.description,
                    });
                } catch (error) {
                    addToast({
                        type: 'error',
                        title: message.error.save,
                        description: 'Tente salvar o bairro manualmente',
                    });
                    console.error(error);
                }
            } else {
                try {
                    const response = await api.get(
                        `district?district.cityIbgeId=${data.ibge}`,
                    );
                    setOptionsDistrict(response.data.data);
                } catch (error) {
                    addToast({
                        type: 'error',
                        title: message.error.selectAll,
                        description: 'Não foi possível selecionar os bairros',
                    });
                    console.error(error);
                }
                setValue('district', undefined);
            }

            setValue('publicPlace', data?.logradouro || '');
            setValue('district.cityName', data.localidade);
            setValue('district.cityIbgeId', data.ibge);
            setValue('district.cityAutocomplete', {
                id: data.ibge,
                description: data.localidade,
            });

            const dataState = await findStateBySigla(data.uf);
            updateCity(dataState.id);
            setValue('district.state', data.uf);
            setValue('district.stateIbgeId', dataState.id);
            setValue('district.stateName', dataState.nome);
            setValue('district.stateAutocomplete', {
                id: dataState.id,
                description: `${data.uf} - ${dataState.nome}`,
            });
        } catch (error) {
            addToast({
                type: 'warn',
                title: message.error.selectOne,
                description:
                    'O CEP deve estar inválido, verifique e digite novamente',
            });
        }
        setOpenLoading(false);
    };

    const updateCity = async (idState: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idState}/municipios`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                  };
              })
            : [];
        setOptionsCity(data);
        return data;
    };

    const onChangeState = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const [uf, nome] = option.description.split(' - ');
            setValue('district.state', uf);
            setValue('district.stateName', nome);
            setValue('district.stateIbgeId', option.id);
            await updateCity(option.id);
        }
    };

    const onChangeCity = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const response = await api.get(
                `district?district.cityIbgeId=${option.id}`,
            );
            setOptionsDistrict(response.data.data);
            setValue('district.cityName', option.description);
            setValue('district.cityIbgeId', option.id);
        }
    };

    const inputsFormAccordion: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'district.cep',
            control: control,
            label: 'CEP',
            md: 2,
            xs: 12,
            toolTipPros: {
                title: 'Digite o CEP para que a busca do endereço possa ser feita',
                arrow: true,
            },
            mask: 'cep',
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo CEP é obrigatório',
                },
            ],
            setValue,
            handleOnBlur: handleOnBlurCep,
        },
        {
            typeInput: 'autocomplete',
            name: 'district.stateAutocomplete',
            control: control,
            label: 'Estado',
            md: 3,
            xs: 12,
            options: optionsState,
            loadingAutocomplete: false,
            setValue,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo estado é obrigatório',
                },
            ],
            handleChange: onChangeState,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'district.cityAutocomplete',
            control: control,
            label: 'Cidade',
            md: 3,
            xs: 12,
            options: optionsCity,
            loadingAutocomplete: false,
            setValue,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo cidade é obrigatório',
                },
            ],
            handleChange: onChangeCity,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'district',
            control: control,
            label: 'Bairro',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsDistrict,
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo bairro é obrigatório',
                },
            ],
            actionOnInput: {
                action: () => {
                    setOpenModalDistrict(true);
                },
                iconProps: {
                    icon: icons.addCircle,
                },
                textTooltip: 'Adicionar bairro',
            },
            activeDebounce: true,
        },
        {
            typeInput: 'text',
            name: 'publicPlace',
            control: control,
            label: 'Logradouro',
            md: 5,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo logradouro é obrigatório',
                },
            ],
        },
        {
            typeInput: 'text',
            name: 'addressComplement',
            control: control,
            label: 'Complemento',
            md: 3,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'number',
            control: control,
            label: 'Número',
            md: 2,
            xs: 12,
        },
    ];

    const getOrder = async () => {
        const queryTemp = queryToObj(query);
        const queryString = objToQuery(queryTemp, [
            'expandedOutputProduct',
            'expandedTotalPurchases',
            'expandedOrder',
            'pageOutputProduct',
        ]);

        if (queryLast !== queryString) {
            setOpenLoading(true);
            setQueryLast(queryString);
            try {
                const response = await api.get(`work-order?${queryString}`);
                setRowOrders(response.data.data);
                setOpenLoading(false);
            } catch (error) {
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: '',
                });
                setOpenLoading(false);
            }
        }
    };

    useEffect(() => {
        let getParam = getParams();

        if (
            search &&
            search.length > 0 &&
            getParam.expandedOrder === 'panel-order' &&
            !expandedOrder
        ) {
            if (getParam.page && getParam.page > 0) {
                setPage(Number(getParam.page) - 1);
            }

            setExpandedOrder('panel-order');
        } else if (getParam.expandedOrder === 'panel-order') {
            getParam.page = Number(page) + 1;
            setQuery(objToQuery(getParam));
        }
    }, [search]);

    function getParams() {
        let paramsQueryString = {
            limit: 5,
            page: 0,
            pageOutputProduct: 0,
            'customer.id': '',
            expandedOutputProduct: '',
            expandedOrder: '',
            expandedTotalPurchases: '',
            state: '3,5,6',
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (!paramsQueryString.limit) {
            paramsQueryString.limit = 5;
        }
        if (paramsQueryString.page) {
            paramsQueryString.page = Number(paramsQueryString.page) || 0;
        }

        if (paramsQueryString.pageOutputProduct) {
            paramsQueryString.pageOutputProduct =
                Number(paramsQueryString.pageOutputProduct) || 0;
        }
        if (params.id) {
            paramsQueryString['customer.id'] = params.id;
        }
        if (paramsQueryString.expandedOutputProduct) {
            paramsQueryString.expandedOutputProduct =
                paramsQueryString.expandedOutputProduct;
        }
        if (paramsQueryString.expandedTotalPurchases) {
            paramsQueryString.expandedTotalPurchases =
                paramsQueryString.expandedTotalPurchases;
        }
        if (paramsQueryString.expandedOrder) {
            paramsQueryString.expandedOrder = paramsQueryString.expandedOrder;
        }
        return paramsQueryString;
    }

    const setModel = (data: IFormInput) => {
        setValue('name', data.name);
        setValue('identification', cpfAndCnpjMask(data.identification, false));
        setValue('birthDate', data.birthDate);
        if (data.birthDate)
            setValue(
                'formattedBirthDate',
                format(new Date(data.birthDate), 'dd/MM/yyyy'),
            );
        setValue('email', data.email);
        setValue('phones', data.phones);
        setValue('publicPlace', data.publicPlace);
        setValue('number', data.number);
        setValue('addressComplement', data.addressComplement);
        setValue('district', data.district);

        setValue('district.state', data.district?.state || '');
        setValue('district.stateIbgeId', data.district?.stateIbgeId);
        if (data.district?.stateIbgeId) {
            setValue('district.stateAutocomplete', {
                id: `${data.district.stateIbgeId}`,
                description: `${data.district.state} - ${data.district.stateName}`,
            });
        }
        setValue('district.cityName', data.district?.cityName);
        setValue('district.cityIbgeId', data.district?.cityIbgeId);
        setValue('district.cityAutocomplete', {
            id: data.district?.cityIbgeId || '',
            description: data.district?.cityName || '',
        });

        setValue(
            'district.cep',
            data.district?.cep && cepMask(data.district.cep, false),
        );
    };

    const submit = async (data: any) => {
        try {
            if (!fields || fields.length < 1) {
                addToast({
                    type: 'warn',
                    title: message.warn.save,
                    description: 'Tem que ter ao menos um número para contato.',
                });
                return;
            }

            delete data.stateAutocomplete;
            delete data.cityAutocomplete;

            if (data.cep) {
                data.cep = data.cep.replace(/\D/g, '');
            }
            data.identification = data.identification.replace(/\D/g, '');
            if (data.cep) {
                data.cep = data.cep.replace(/\D/g, '');
            }
            setOpenLoading(true);
            if (params && params.id) {
                await api.patch(`customer/${params.id}`, data);
            } else {
                await api.post('customer', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.customer}?`);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`customer/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.customer}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    const handleChangeAccordion =
        (panel: 'panel-address') =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const updateState = async () => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      initials: d.sigla,
                  };
              })
            : [];

        setOptionsState(data);
    };

    const findStateBySigla = async (sigla: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}`,
        );

        return {
            id: response.data.id,
            sigla: response.data.sigla,
            nome: response.data.nome,
        };
    };

    const findCity = async (id: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`,
        );

        return {
            id: response.data.id,
            description: response.data.nome,
            UF: findVal(response.data, 'UF'),
        };
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <RenderForm inputsForm={inputsForm} />
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded === 'panel-address'}
                                onChange={handleChangeAccordion(
                                    'panel-address',
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Endereço</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <RenderForm
                                            inputsForm={inputsFormAccordion}
                                        />
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Accordion
                                expanded={expandedOrder === 'panel-order'}
                                onChange={handleChangeAccordionOrders(
                                    'panel-order',
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Ordens de serviço</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <TableContainer>
                                            <Table
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        {headCells.map(
                                                            headCell => (
                                                                <TableCell
                                                                    key={
                                                                        headCell.id
                                                                    }
                                                                    align={
                                                                        headCell.numeric
                                                                            ? 'right'
                                                                            : 'left'
                                                                    }
                                                                    padding={
                                                                        headCell.disablePadding
                                                                            ? 'none'
                                                                            : 'normal'
                                                                    }>
                                                                    {
                                                                        headCell.label
                                                                    }
                                                                </TableCell>
                                                            ),
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rowOrders.map(row => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.id}>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {row.code}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {
                                                                        stateFind(
                                                                            row?.state,
                                                                        )
                                                                            ?.description
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {formatDate(
                                                                        row?.dateEntry,
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {formatDate(
                                                                        row?.dateCompletion,
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {`${row?.modelBrand?.brand?.description} - ${row?.modelBrand?.description}`}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {
                                                                        row
                                                                            .technician
                                                                            .name
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {formatNumber(
                                                                        row.serviceValue,
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {emptyRows() > 0 && (
                                                        <>
                                                            <TableRow
                                                                style={{
                                                                    height:
                                                                        32 *
                                                                        emptyRows(),
                                                                }}></TableRow>
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            component="div"
                                            count={total}
                                            labelRowsPerPage={
                                                'Linhas por página'
                                            }
                                            labelDisplayedRows={
                                                defaultLabelDisplayedRows
                                            }
                                            rowsPerPageOptions={[]}
                                            rowsPerPage={5}
                                            page={total > 0 ? page : 0}
                                            onPageChange={handleChangePage}
                                        />
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>
                        </Grid> */}
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
                <ModalDistrictType
                    open={openModalDistrict}
                    setOpen={setOpenModalDistrict}
                    width={width}
                    useFormItems={useFormInput}
                />
                <ModalPhones
                    open={openModalPhones}
                    setOpen={setOpenModalPhones}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    width={width}
                    useFormItems={useFormItems}
                    inputs={
                        inputsForm.find(i => i.typeInput === 'subForm')
                            ?.dataTable?.inputs || []
                    }
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalPhones({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    width,
    inputs,
    useFormItems,
}: ModalProps) {
    const { addToast } = useToast();

    const defaultValuesItems = {
        phoneType: '',
        phoneDdd: '047',
        phoneNumber: '',
        phoneBranch: '',
        phoneTypeOption: undefined,
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index, open]);

    const setModel = (index: number) => {
        const phoneTypeId = getValues(`phones[${index}].phoneType`);
        const pType = typeNumberPhoneFind(phoneTypeId);
        if (pType?.id) {
            setValueItems('phoneType', phoneTypeId);
            setValueItems('phoneTypeOption', {
                id: pType?.id,
                description: pType?.description,
            });
        }
        setValueItems('phoneType', getValues(`phones[${index}].phoneType`));
        setValueItems('phoneDdd', getValues(`phones[${index}].phoneDdd`));
        setValueItems(
            'phoneNumber',
            phoneMask(getValues(`phones[${index}].phoneNumber`), false),
        );
        setValueItems('branch', getValues(`phones[${index}].branch`));
    };

    const handleClose = () => {
        setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const submitItem = (data: IPhone) => {
        data.phoneNumber = data.phoneNumber.replace(/\D/g, '');
        const repeatItem = getValues('phones')
            ? getValues('phones').filter(
                  (f: IPhone, i: number) =>
                      index != i && f.phoneNumber == data.phoneNumber,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir item
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <RenderForm inputsForm={inputs} />
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalDistrictType({
    open,
    setOpen,
    useFormItems,
    width,
}: ModalDistrictProps) {
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();

    const defaultValues = {
        description: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const useFormModal = useForm<any>({
        defaultValues: defaultValues,
    });
    const controlItems = useFormModal.control;
    const handleSubmit = useFormModal.handleSubmit;
    const reset = useFormModal.reset;

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlItems,
            label: 'Descrição',
            loadingAutocomplete: false,
            md: 12,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo descrição é obrigatório',
                },
            ],
            autoFocus: true,
        },
    ];

    const handleClose = () => {
        setOpen(false);
        reset(defaultValues);
    };

    const submitItem = async (data: any) => {
        try {
            if (
                !useFormItems.getValues('district.state') ||
                !useFormItems.getValues('district.cityName')
            ) {
                addToast({
                    type: 'warn',
                    title: 'Preencha o formulário na sequência',
                    description:
                        'Na seguinte ordem: estado, cidade e em seguida salve o novo bairro',
                });
                return;
            }

            setOpenLoading(true);
            const district = useFormItems.getValues('district');
            if (district) {
                data = {
                    ...data,
                    cityIbgeId: district.cityIbgeId,
                    cityName: district.cityName,
                    stateIbgeId: district.stateIbgeId,
                    state: district.state,
                    stateName: district.stateName,
                    cep: district.cep?.replace('-', ''),
                };
            }
            const response = await api.post('district', data);

            useFormItems.setValue('district', response.data);
            addToast({
                type: 'success',
                title: message.success.save,
                description: 'Bairro salvo',
            });
            handleClose();
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
        }
        setOpenLoading(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Adicionar bairro
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <RenderForm inputsForm={inputsForm} />
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar e aplicar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;
