import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { createTheme, styled } from '@mui/material/styles';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import io from 'socket.io-client';
import AppProvider from './context';
import Routes from './routes';
import { urls } from './config';

const App: React.FC = () => {
    const baseURL =
        process.env.REACT_APP_ENVIROMENT === 'local'
            ? urls.api.local
            : urls.api.prod;
    // const socket = io(baseURL, { transports: ['websocket'] });

    const [message, setMessage] = React.useState<any>({});
    // React.useEffect(() => {
    //     socket.on('msgToClient', (messageTest: any) => {
    //         setMessage(messageTest);
    //     });
    // }, []);

    return (
        <>
            <Router>
                <AppProvider message={message}>
                    <Routes />
                    {/* <InterceptorComponent /> */}
                </AppProvider>
            </Router>
        </>
    );
};

export default App;
