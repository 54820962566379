import MainContainer from '../main-container';
import Form from './roleForm';
import List from './roleList';

const Role: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const RoleList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Role, RoleList };
