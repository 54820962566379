import MainContainer from '../main-container';
import Form from './companyForm';
import List from './companyList';

const Company: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const CompanyList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Company, CompanyList };
