import React from 'react';
import { Switch } from 'react-router-dom';
import { paths } from './config';
// import Route from './Route'
// IMPORTS ADMIN
// IMPORTS CLIENT
import { OriginType, OriginTypeList } from './pages/origin-type/originType';
import { Customer, CustomerList } from './pages/customer/customer';
import Dashboard from './pages/dashboard';
import {
    LegalProcess,
    LegalProcessList,
} from './pages/legal-process/legalProcess';
import {
    AdditionalHonorarium,
    AdditionalHonorariumList,
} from './pages/additional-honorarium/additionalHonorarium';
import {
    AdditionalExpense,
    AdditionalExpenseList,
} from './pages/additional-expense/additionalExpense';
import {
    OriginExpenditure,
    OriginExpenditureList,
} from './pages/origin-expenditure/originExpenditure';
import { Company, CompanyList } from './pages/company/company';
import { Permission, PermissionList } from './pages/permission/permission';
import { Right, RightList } from './pages/right/right';
import { Role, RoleList } from './pages/role/role';
import { District, DistrictList } from './pages/district/district';
import {
    Configuration,
    ConfigurationList,
} from './pages/configuration/configuration';
import SignIn from './pages/sign-in/sign-in';
import { User, UserList } from './pages/user/user';
import {
    Withdrawn,
    WithdrawnList,
} from './pages/invoice-details/withdrawn/withdrawn';
import Paid from './pages/paid';
import Route from './Route';
import {
    InvoiceDetailPayment,
    InvoiceDetailList,
    InvoiceDetailRegister,
    GeneratedInvoice,
} from './pages/invoice-details/invoiceDetail';

const Routes: React.FC = () => (
    <Switch>
        <Route path={`/`} exact component={SignIn} />
        <Route path={`/dashboard`} isPrivate={true} component={Dashboard} />

        {/*INICIO - cadastros gerais */}
        <Route
            path={`${paths.userRegister}/:id?`}
            isPrivate={true}
            component={User}
        />
        <Route path={`${paths.user}`} isPrivate={true} component={UserList} />
        <Route
            path={`${paths.originTypeRegister}/:id?`}
            isPrivate={true}
            component={OriginType}
        />
        <Route
            path={`${paths.originType}`}
            isPrivate={true}
            component={OriginTypeList}
        />
        <Route
            path={`${paths.customerRegister}/:id?`}
            isPrivate={true}
            component={Customer}
        />
        <Route
            path={`${paths.customer}/:id?`}
            isPrivate={true}
            component={CustomerList}
        />
        <Route
            path={`${paths.districtRegister}/:id?`}
            isPrivate={true}
            component={District}
        />
        <Route
            path={`${paths.district}`}
            isPrivate={true}
            component={DistrictList}
        />
        <Route
            path={`${paths.configurationRegister}/:id?`}
            isPrivate={true}
            component={Configuration}
        />
        <Route
            path={`${paths.configuration}/:id?`}
            isPrivate={true}
            component={ConfigurationList}
        />
        {/*FIM - cadastros gerais */}

        <Route
            isPrivate={true}
            path={`${paths.legalProcessRegister}/:id?`}
            component={LegalProcess}
        />
        <Route
            isPrivate={true}
            path={`${paths.legalProcess}`}
            component={LegalProcessList}
        />
        <Route
            isPrivate={true}
            path={`${paths.additionalHonorariumRegister}/:id?`}
            component={AdditionalHonorarium}
        />
        <Route
            isPrivate={true}
            path={`${paths.additionalHonorarium}`}
            component={AdditionalHonorariumList}
        />
        <Route isPrivate={true} path={`${paths.paid}`} component={Paid} />
        <Route
            isPrivate={true}
            path={`${paths.invoiceDetail}`}
            component={InvoiceDetailPayment}
        />
        <Route
            isPrivate={true}
            path={`${paths.invoiceDetailList}`}
            component={InvoiceDetailList}
        />
        <Route
            isPrivate={true}
            path={`${paths.invoiceDetailRegister}/:id/:typeQuery/:userId/:callbackRedirect`}
            component={InvoiceDetailRegister}
        />
        <Route
            isPrivate={true}
            path={`${paths.generatedInvoices}`}
            component={GeneratedInvoice}
        />
        <Route
            isPrivate={true}
            path={`${paths.additionalExpenseRegister}/:id?`}
            component={AdditionalExpense}
        />
        <Route
            isPrivate={true}
            path={`${paths.additionalExpense}`}
            component={AdditionalExpenseList}
        />
        <Route
            isPrivate={true}
            path={`${paths.permissionRegister}/:id?`}
            component={Permission}
        />
        <Route
            isPrivate={true}
            path={`${paths.permission}`}
            component={PermissionList}
        />
        <Route
            isPrivate={true}
            path={`${paths.originExpenditureRegister}/:id?`}
            component={OriginExpenditure}
        />
        <Route
            isPrivate={true}
            path={`${paths.originExpenditure}`}
            component={OriginExpenditureList}
        />
        <Route
            isPrivate={true}
            path={`${paths.withdrawnRegister}/:id?`}
            component={Withdrawn}
        />
        <Route
            isPrivate={true}
            path={`${paths.withdrawn}`}
            component={WithdrawnList}
        />
        <Route
            isPrivate={true}
            path={`${paths.companyRegister}/:id?`}
            component={Company}
        />
        <Route
            isPrivate={true}
            path={`${paths.company}`}
            component={CompanyList}
        />
        <Route
            isPrivate={true}
            path={`${paths.rightRegister}/:id?`}
            component={Right}
        />
        <Route isPrivate={true} path={`${paths.right}`} component={RightList} />
        <Route
            isPrivate={true}
            path={`${paths.roleRegister}/:id?`}
            component={Role}
        />
        <Route isPrivate={true} path={`${paths.role}`} component={RoleList} />
        {/* <Route path="/records/category" component={ListEntity} /> */}
    </Switch>
);

export default Routes;
