import {
    Box,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    TableCell,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    GenericEnum,
    stateLegalProcessOptions,
} from '../../communs/enums/generic-enum';
import { FormInputProps } from '../../componets/form/formInterfaces';
import IDescription from '../../util/interfaces/description';
import IName from '../../util/interfaces/name';
import { useAuth } from '../../context/AuthContext';
import RenderForm from '../../componets/form/renderForm';
import { momentZoneToDate } from '../../util/dateUtil';
import { ColumnDef } from '@tanstack/react-table';
import { IconComponent, icons } from '../../componets/icons';
import { useWindowSize } from '../../util/responsiveness';
import { FormInputText } from '../../componets/form/formInputText';
import { TableGeneric } from '../../componets/table';
import { formatNumber } from '../../util/infoFormat';

interface IFormInput {
    code: string;
    dateSpend: Date | undefined;
    customer?: IDescription;
    user?: IDescription;
    processNumber: string;
    itemExpenses: ItemExpense[];
    generateInstallments?: boolean;
}

interface ItemExpense {
    originExpenditure: {
        id: string;
        description: string;
    };
    description: string;
    quantity: number;
    itemValue: number;
}

interface IUpdateAutocomplete {
    description: string;
    keyField: 'customer' | 'user' | 'originExpenditure';
}

interface IUpdateSearchByProcessNumber {
    description: string;
    keyField: 'legalProcess';
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    setIndex: (index: number) => void;
    getValues: any;
    append: any;
    update: any;
    width: number;
    inputs: FormInputProps<ItemExpense>[];
    useFormItems: UseFormReturn<ItemExpense>;
}

interface ModalOriginTypeProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    useFormItems: UseFormReturn<ItemExpense>;
    width: number;
}

interface ModalProcessProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    width: number;
    setValue: any;
}

interface DataProcess {
    customer?: IName;
    processNumber?: string;
}

const defaultValues = {
    dateSpend: momentZoneToDate(undefined),
    honoraryValue: 0,
    generateInstallments: false,
    user: undefined,
};

const instanceStateOptions = new GenericEnum(stateLegalProcessOptions);

const settings = {
    pathRegister: paths.additionalExpenseRegister,
    pathList: paths.additionalExpense,
    apiPath: 'additional-expense',
    permissionAdmin: 'additional_expense_execute',
    tittle: 'Despesas adicionais',
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [customerOptions, setCustomerOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [originExpenditureOptions, setOriginTypeOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [userOptions, setUserOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [indexItem, setIndexItem] = useState(-1);
    const [width] = useWindowSize();
    const [openProcessModal, setOpenProcessModal] = useState(false);
    const [openModalOriginType, setOpenModalOriginType] = useState(false);

    const { user } = useAuth();

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'itemExpenses',
        'itemExpensesId'
    >({
        control,
        name: 'itemExpenses',
        keyName: 'itemExpensesId',
    });

    const useFormItems = useForm<ItemExpense>({
        defaultValues: {
            quantity: 1,
            itemValue: undefined,
            description: '',
        },
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        if (!params?.id) {
            setTimeout(() => {
                setValue('dateSpend', defaultValues.dateSpend);
                setValue(
                    'generateInstallments',
                    !defaultValues.generateInstallments,
                );
            }, 100);
        }
    }, []);

    useEffect(() => {
        const totalHonorarium = fields.reduce((accumulator, item) => {
            const quantity = Number(item.quantity);
            const itemValue = Number(item.itemValue);
            return accumulator + quantity * itemValue;
        }, 0);

        setTotal(totalHonorarium);
    }, [fields]);

    useEffect(() => {
        let queryAdd = '';
        if (user && !user.permissions[settings.permissionAdmin]) {
            queryAdd = `?user.id=${user.sub}`;
        }

        if (!params?.id) {
            api.get(`users/${queryAdd}`)
                .then(response => {
                    setModel(response.data);
                    const users = response.data.data.map((u: any) => ({
                        id: u.id,
                        description: u.name,
                    }));
                    setUserOptions(users);

                    const findUser = users.find((u: IName) => u.id == user.sub);
                    setValue('user', findUser);

                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }

        if (params && params.id) {
            setOpenLoading(true);
            api.get(`${settings.apiPath}/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    const autocompleteSettings = {
        customer: {
            query: (value: string) => `customer?name=${value}`,
            setOptions: (options: any[]) => {
                setCustomerOptions(options);
            },
        },
        user: {
            query: (value: string) => `users?name=${value}`,
            setOptions: (options: any[]) => {
                setUserOptions(options);
            },
        },
        originExpenditure: {
            query: (value: string) => `origin-expenditure?description=${value}`,
            setOptions: (options: any[]) => {
                setOriginTypeOptions(options);
            },
        },
    };

    const updateAutocomplete = async ({
        description,
        keyField,
    }: IUpdateAutocomplete) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            try {
                const response = await api.get(
                    autocompleteSettings[keyField].query(description),
                );
                autocompleteSettings[keyField].setOptions(response.data.data);
            } catch (error) {
                const messagesResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messagesResponse,
                });
            }
            setLoadingAutocomplete(false);
        }
    };

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            let keyField: 'customer' | 'user' | 'originExpenditure' =
                'customer';
            if (event.target.id.includes('user')) {
                keyField = 'user';
            } else if (event.target.id.includes('originExpenditure')) {
                keyField = 'originExpenditure';
            }

            updateAutocomplete({ description: event.target.value, keyField });
        }
    };

    const handleClick = (index: any) => {
        setIndexItem(index);
        setOpenModal(true);
    };

    const columnsTable = React.useMemo<ColumnDef<ItemExpense>[]>(
        () => [
            {
                id: 'originExpenditure',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Tipo de origem
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {row.original?.originExpenditure.description}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'description',
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() => handleClick(cell.row.id)}>
                            {cell.row.original.description}
                        </TableCell>
                    );
                },
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
            },
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Valor
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {formatNumber(row.original?.itemValue)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Quantidade
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {row.original?.quantity}
                        </TableCell>
                    );
                },
            },
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Sub total
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {formatNumber(
                                row.original?.itemValue *
                                    row.original?.quantity,
                            )}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleClick(row.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                handleDeleteItemTable(row.id);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'component',
            name: 'code',
            control: control,
            label: 'Código',
            md: 12,
            xs: 12,
            componentProps: {
                component: (
                    <Typography
                        sx={{ flex: '1 1 100%', fontSize: '1rem' }}
                        variant="h6"
                        id="tableTitle"
                        component="div">
                        {getValues('code')
                            ? `Código: ${getValues('code')}`
                            : ''}
                    </Typography>
                ),
                conditionRender: params && params.id,
            },
        },
        {
            typeInput: 'text',
            name: 'processNumber',
            control: control,
            label: 'Número do processo',
            loadingAutocomplete: false,
            md: 3,
            xs: 12,
            actionOnInput: {
                action: () => {
                    setOpenProcessModal(true);
                },
                iconProps: {
                    icon: icons.search,
                },
                textTooltip: 'Pesquise aqui o número do processo',
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'customer',
            control: control,
            label: 'Cliente',
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: customerOptions,
            md: 6,
            xs: 12,
            handleChange: onChange,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'user',
            control: control,
            label: 'Advogado',
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: userOptions,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo Advogado(a) é obrigatório',
                },
            ],
            handleChange: onChange,
            activeDebounce: true,
        },
        {
            typeInput: 'date',
            name: 'dateSpend',
            control: control,
            label: 'Data',
            setValue: setValue,
            md: 3,
            xs: 12,
            placeholder: 'dd/mm/aaaa hh:mm',
            rules: {
                validate: (value: any) => {
                    if (value && String(value) === 'Invalid Date') {
                        return 'A data está em um formato inválido';
                    }
                },
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo data é obrigatório',
                },
            ],
        },
        {
            typeInput: 'checkbox',
            name: 'generateInstallments',
            control: control,
            label: 'Gerar parcelas',
            md: 3,
            xs: 12,
        },
        {
            typeInput: 'subForm',
            name: 'subForm',
            control,
            label: 'Itens',
            md: 12,
            xs: 12,
            dataTable: {
                propsTable: {
                    columns: columnsTable,
                    data: fields,
                    minWidth: 600,
                },
                handleNewItemTable: () => {
                    setOpenModal(true);
                    setIndexItem(-1);
                },
                inputs: [
                    {
                        typeInput: 'autocomplete',
                        name: 'originExpenditure',
                        control: controlItems,
                        label: 'Tipo de origem',
                        loadingAutocomplete: loadingAutocomplete,
                        setValue: setValueItems,
                        options: originExpenditureOptions,
                        md: 6,
                        xs: 12,
                        rules: {
                            required: true,
                        },
                        messagesError: [
                            {
                                type: 'required',
                                message: 'O campo tipo de origem é obrigatório',
                            },
                        ],
                        handleChange: onChange,
                        actionOnInput: {
                            action: () => {
                                setOpenModalOriginType(true);
                            },
                            iconProps: {
                                icon: icons.addCircle,
                            },
                            textTooltip: 'Adicionar tipo de origem',
                        },
                    },
                    {
                        typeInput: 'text',
                        name: 'description',
                        control: controlItems,
                        label: 'Descrição',
                        loadingAutocomplete: false,
                        md: 6,
                        xs: 12,
                        messagesError: [
                            {
                                type: 'required',
                                message: 'O campo descrição é obrigatório',
                            },
                        ],
                    },
                    {
                        typeInput: 'number',
                        name: 'quantity',
                        control: controlItems,
                        label: 'Quantidade',
                        md: 6,
                        xs: 12,
                        decimalScale: 0,
                        rules: {
                            validate: (value: number) => {
                                if (value && value < 0) {
                                    return 'O campo quantidade não pode ser menor que zero';
                                }
                            },
                        },
                    },
                    {
                        typeInput: 'number',
                        name: 'itemValue',
                        control: controlItems,
                        label: 'Valor',
                        md: 6,
                        xs: 12,
                        decimalScale: 2,
                        rules: {
                            validate: (value: number) => {
                                if (value && value < 0) {
                                    return 'O campo valor não pode ser menor que zero';
                                }
                            },
                        },
                    },
                ] as FormInputProps<ItemExpense>[],
            },
        },
    ];

    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    const setModel = (data: any) => {
        setValue('code', data.code);
        setValue('processNumber', data.processNumber);
        if (data.customer && data.customer.id) {
            setValue('customer', {
                description: data.customer.name,
                id: data.customer.id,
            });
        }
        if (data.user && data.user.id) {
            setValue('user', {
                description: data.user.name,
                id: data.user.id,
            });
        }
        setValue('dateSpend', data.dateSpend);
        setValue('itemExpenses', data.itemExpenses);
    };

    const submit = async (data: any, e: any) => {
        e.preventDefault();
        try {
            setOpenLoading(true);

            for (const input of inputsForm) {
                if (!input?.dto) {
                    continue;
                }
                data = input.dto(data);
            }

            let response = { data: { id: null } };
            if (params && params.id) {
                response = await api.patch(
                    `${settings.apiPath}/${params.id}`,
                    data,
                );
            } else {
                response = await api.post(settings.apiPath, data);
            }
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            reset(defaultValues);

            if (data.generateInstallments) {
                const redirect = Object.keys(paths).find(
                    k => k === 'additionalExpense',
                );
                history.push(
                    `${paths.invoiceDetailRegister}/${response.data.id}/additionalExpense/${data.user.id}/${redirect}`,
                );
            } else {
                history.goBack();
            }
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`${settings.apiPath}/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.legalProcess}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(submit)}>
                    <Grid container spacing={2}>
                        <RenderForm inputsForm={inputsForm} />
                    </Grid>
                    <br />

                    <div className="info-footer">
                        <div className="footer-buttons">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            {params.id && (
                                <FormButton
                                    label={'Excluir'}
                                    typeButton={'delete'}
                                    onClick={() => setOpenModalDelete(true)}
                                />
                            )}
                            <FormButton
                                label={'Voltar'}
                                typeButton={'cancel'}
                                onClick={() => handleCancel()}
                            />
                        </div>
                        <Typography id="total" component="div">
                            {`Total: ${formatNumber(total)}`}
                        </Typography>
                    </div>
                </form>
                <ModalItem
                    open={openModal}
                    setOpen={setOpenModal}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    width={width}
                    useFormItems={useFormItems}
                    inputs={
                        inputsForm.find(i => i.typeInput === 'subForm')
                            ?.dataTable?.inputs || []
                    }
                />
                <ModalSaveOriginType
                    open={openModalOriginType}
                    setOpen={setOpenModalOriginType}
                    width={width}
                    useFormItems={useFormItems}
                />
                <ModalProcess
                    open={openProcessModal}
                    setOpen={setOpenProcessModal}
                    setValue={setValue}
                    width={width}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalItem({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    width,
    inputs,
    useFormItems,
}: ModalProps) {
    const defaultValuesItems = {
        itemValue: undefined,
        quantity: undefined,
        description: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index, open]);

    const setModel = (index: number) => {
        setValueItems(
            'originExpenditure',
            getValues(`itemExpenses[${index}].originExpenditure`),
        );
        setValueItems(
            'description',
            getValues(`itemExpenses[${index}].description`),
        );
        setValueItems('quantity', getValues(`itemExpenses[${index}].quantity`));
        setValueItems(
            'itemValue',
            getValues(`itemExpenses[${index}].itemValue`),
        );
    };

    const handleClose = () => {
        setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const submitItem = (data: any) => {
        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir item
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <RenderForm inputsForm={inputs} />
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalSaveOriginType({
    open,
    setOpen,
    useFormItems,
    width,
}: ModalOriginTypeProps) {
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();

    const defaultValues = {
        description: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const useFormModal = useForm<any>({
        defaultValues: defaultValues,
    });
    const controlItems = useFormModal.control;
    const handleSubmit = useFormModal.handleSubmit;
    const reset = useFormModal.reset;

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlItems,
            label: 'Descrição',
            loadingAutocomplete: false,
            md: 12,
            xs: 12,
            rules: {
                required: true,
            },
            autoFocus: true,
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo descrição é obrigatório',
                },
            ],
        },
    ];

    const handleClose = () => {
        setOpen(false);
        reset(defaultValues);
    };

    const submitItem = async (data: any) => {
        setOpenLoading(true);
        try {
            const response = await api.post('origin-expenditure', data);

            useFormItems.setValue('originExpenditure', response.data);
            addToast({
                type: 'success',
                title: message.success.save,
                description: 'Tipo de origem salvo',
            });
            handleClose();
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
        }
        setOpenLoading(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Adicionar tipo de origem
                    </Typography>
                    <form
                        onSubmit={handleSubmit((data: any) =>
                            submitItem(data),
                        )}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <RenderForm inputsForm={inputsForm} />
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar e aplicar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

const styleModalProcess = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalProcess({ open, setOpen, width, setValue }: ModalProcessProps) {
    const { control } = useForm<any>({
        defaultValues: { findByProcessNumber: '' },
    });
    const [focus, setFocus] = useState(true);
    const { addToast } = useToast();

    const [data, setData] = useState<DataProcess[]>([]);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (data: DataProcess) => {
        setValue('processNumber', data.processNumber);
        setValue('customer', data.customer);
        setOpen(false);
    };

    const styleModal = {
        ...styleModalProcess,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const columnsTable = React.useMemo<ColumnDef<DataProcess>[]>(
        () => [
            {
                id: 'processNumber',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Número processo
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original)}>
                            {row.original?.processNumber}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'customer',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Cliente
                    </TableCell>
                ),
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() => handleClick(cell.row.original)}>
                            {cell.row.original.customer.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'situation',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Situação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            key={row.id}
                            align="left"
                            onClick={() => handleClick(row.original)}>
                            {
                                instanceStateOptions.getObject(
                                    row.original.situation,
                                )?.description
                            }
                        </TableCell>
                    );
                },
            },
        ],
        [],
    );

    const searchSettings = {
        legalProcess: {
            query: (value: string) =>
                `legal-process/free?legalProcess.processNumber=${value}`,
            setOptions: (options: DataProcess[]) => {
                setData(options);
            },
        },
    };

    const updateAutocomplete = async ({
        description,
        keyField,
    }: IUpdateSearchByProcessNumber) => {
        if (description && description.length > 0) {
            setLoading(true);
            try {
                const response = await api.get(
                    searchSettings[keyField].query(description),
                );
                searchSettings[keyField].setOptions(response.data.data);
            } catch (error: any) {
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messageResponse,
                });
            }
            setLoading(false);
        }
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            let keyField: 'legalProcess' = 'legalProcess';
            updateAutocomplete({ description: data.target.value, keyField });
            setFocus(false);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal" style={{ marginBottom: 8 }}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            {'Pesquisa de processos'}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <IconComponent icon={icons.close} />
                        </IconButton>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <FormInputText
                                name={'findByProcessNumber'}
                                control={control}
                                label={'Número do processo'}
                                handleOnKeyPress={handleOnKeyPress}
                                autoFocus={focus}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: loading
                                        ? 'center'
                                        : 'flex-start',
                                    justifyContent: 'center',
                                    minHeight: 400,
                                }}>
                                {loading ? (
                                    <CircularProgress color="inherit" />
                                ) : (
                                    <TableGeneric
                                        data={data}
                                        columns={columnsTable}
                                        minWidth={650}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;
