import { Delete, ModeEdit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Container, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import FilterSearch from '../../componets/filter/filterSearch';
import { FormButton } from '../../componets/form/formButton';
import {
    FormInputText,
    IFormInputProps,
} from '../../componets/form/formInputText';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { phoneMask } from '../../componets/form/mask/phone';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { formatDateWithoutHours } from '../../util/dateUtil';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../util/query';
import './customerList.scss';
import FilterSearchComponent from '../../componets/filterComponent/filterSearch';
import { IconComponent, icons } from '../../componets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { TableGeneric } from '../../componets/table';
import { IFormInputPropsFilter } from '../../componets/interfaces/formInputPropsFilter';

interface IPhone {
    phoneType: string;
    phoneDdd: string;
    phoneNumber: string;
    branch: string;
    id?: string;
}

interface Data {
    id: string;
    name: string;
    identification: string;
    birthDate: Date;
    email: string;
    phones: IPhone[];
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date';
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
    },
    {
        id: 'identification',
        numeric: false,
        disablePadding: false,
        label: 'Identificação',
    },
    {
        id: 'birthDate',
        numeric: false,
        disablePadding: false,
        label: 'Data de nascimento',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'E-mail',
    },
    {
        id: 'phones',
        numeric: false,
        disablePadding: false,
        label: 'Telefones',
    },
];

const defaultValues = {
    name: '',
    identification: '',
};

const ListEntityCustomer: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [updateRows, setUpdateRows] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const { control, setValue, getValues } = useForm({
        defaultValues,
    });

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            const response = await api.get(`customer?${queryString}`);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (updateRows) {
                setUpdateRows(false);
            }
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    const handleClick = (id: string) => {
        handleEdit(id);
    };

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        data: any,
        defaultFilter: boolean | undefined,
    ) => {
        let identification = undefined;
        if (getValues('identification')) {
            identification = getValues('identification').replace(/\D/g, '');
        }

        const queryObj = queryToObj(search.replace('?', ''));
        const newQuery = {
            ...queryObj,
            name: getValues('name'),
            identification: identification,
            page: 1,
        };
        const query = objToQuery(newQuery);
        history.push(`?${query}`);
        loadRows(query);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery(data, false);
        }
    };

    const QUERY_DEFAULT = `page=1&limit=${rowsPerPageOptions[0]}`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'text',
            name: 'name',
            control: control,
            label: 'Pesquisar pelo nome',
            size: 'small',
            variant: 'standard',
            handleOnKeyPress,
            setValue,
            fullWidth: true,
        },
        {
            typeInput: 'text',
            name: 'identification',
            variant: 'standard',
            control: control,
            label: 'Identificação (CPF/CNPJ)',
            mask: 'identification',
            setValue,
            handleOnKeyPress,
        },
    ];

    const [, ...handleInputs] = inputsFilter;

    const handleEdit = (id: string) => {
        history.push(`${paths.customerRegister}/${id}`);
    };
    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`customer/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setUpdateRows(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const columnsTable = React.useMemo<ColumnDef<Data>[]>(
        () => [
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Nome
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {row.original?.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'identification',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Identificação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {cpfAndCnpjMask(row.original.identification, false)}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'birthDate',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data de nascimento
                    </TableCell>
                ),
                cell: ({ row }) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() => handleClick(row.original.id)}>
                            {formatDateWithoutHours(row.original.birthDate)}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'email',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Email
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {row.original?.email}
                        </TableCell>
                    );
                },
            },
            {
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Telefones
                    </TableCell>
                ),
                accessorKey: 'phones',
                cell: ({ row }) => (
                    <TableCell
                        align="left"
                        onClick={() => handleClick(row.original.id)}>
                        {row.original.phones
                            .filter((p: IPhone, index: number) => index <= 1)
                            .map(p => (
                                <div key={`${p.phoneNumber}-${p.id || 'id'}`}>
                                    {phoneMask(p.phoneNumber, false)}
                                </div>
                            ))}
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleEdit(row.original.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIdDelete(row.original.id);
                                setOpenModalDelete(true);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Clientes
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={2}
                                direction="row"
                                width={'100%'}
                                justifyContent="space-between"
                                alignItems="flex-end">
                                {/* <Controller
                                    name={'name'}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <TextField
                                            size="small"
                                            onChange={event => {
                                                onChange(event);
                                            }}
                                            onKeyPress={(data: any) => {
                                                handleByOnKeyPressEnterNewQuery(
                                                    data,
                                                    true,
                                                );
                                            }}
                                            value={value}
                                            fullWidth
                                            label="Pesquisa pelo nome"
                                            variant="standard"
                                        />
                                    )}
                                /> */}
                                <FormInputText
                                    name={inputsFilter[0].name}
                                    control={inputsFilter[0].control}
                                    label={inputsFilter[0].label}
                                    handleOnKeyPress={
                                        inputsFilter[0].handleOnKeyPress
                                    }
                                    variant={inputsFilter[0].variant}
                                />
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() => history.push(paths.customerRegister)}
                        />
                    </Typography>
                    <TableGeneric
                        data={rows}
                        columns={columnsTable}
                        minWidth={650}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            <FilterSearchComponent
                inputs={inputsFilter}
                setToggleSearch={setToggleSearch}
                toggleSearch={toggleSearch}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                page={page}
                implementationLoadRows={loadRows}
                updateRows={updateRows}
                setUpdateRows={setUpdateRows}
                queryDefault={QUERY_DEFAULT}
                handleInputs={handleInputs}
            />
        </div>
    );
};

export default ListEntityCustomer;
