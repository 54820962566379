import { Delete, ModeEdit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import FilterSearch from '../../componets/filterComponent/filterSearch';
import { FormButton } from '../../componets/form/formButton';
import {
    FormInputText,
    IFormInputProps,
} from '../../componets/form/formInputText';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../util/query';
import './styles.scss';
import { IFormInputPropsFilter } from '../../componets/interfaces/formInputPropsFilter';

interface Data {
    id: string;
    name: string;
    email?: string;
    active?: boolean;
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date';
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'E-mail',
    },
    {
        id: 'active',
        numeric: false,
        disablePadding: false,
        label: 'Ativo',
    },
];

const defaultValues = {
    user: {
        nameDefault: '',
        name: '',
    },
};

const UserList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const params = useParams<{ id?: string }>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const { control, setValue, getValues } = useForm({
        defaultValues,
    });

    const loadRows = async (queryString: String): Promise<void> => {
        setOpenLoading(true);
        try {
            const response = await api.get(`users?${queryString}`);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: messageResponse,
            });
        }
    };

    useEffect(() => {
        let queryT = '';
        if (search && search.length > 0 && (!params || !params.id)) {
            const { limit, pageParam } = getParams();
            setRowsPerPage(Number(limit));
            setPage(Number(pageParam - 1));
            queryT = search.replace('?', '');
            setQuery(queryT);
        } else if (rowsPerPage === 0 && (!params || !params.id)) {
            let limit = rowsPerPage === 0 ? rowsPerPageOptions[0] : rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }

        if (queryT.length > 0) {
            loadRows(queryT);
        }
    }, [search]);

    useEffect(() => {
        const { limit, pageParam, description } = getParams();
        if (
            rowsPerPage > 0 &&
            (limit != rowsPerPage || page + 1 != pageParam)
        ) {
            let limit = rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                description,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (deleteRegister) {
            loadRows(query);
        }
    }, [deleteRegister]);

    const handleClick = (event: MouseEvent<unknown>, id: string) => {
        handleEdit(id);
    };

    function getParams() {
        let paramsQueryString = {
            limit: 0,
            pageParam: 0,
            page: 0,
            description: null,
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (paramsQueryString.limit) {
            paramsQueryString.limit = Number(paramsQueryString.limit) || 0;
        }
        if (paramsQueryString.page) {
            paramsQueryString.pageParam = Number(paramsQueryString.page) || 0;
        }
        if (paramsQueryString.description) {
            paramsQueryString.description =
                paramsQueryString.description || null;
        }
        return paramsQueryString;
    }

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        data: any,
        defaultFilter: boolean | undefined,
    ) => {
        if (data.charCode === 13) {
            const queryObj = queryToObj(query);
            const newQuery = {
                ...queryObj,
                'user.name': defaultFilter
                    ? getValues('user.nameDefault')
                    : getValues('user.name'),
                page: 1,
            };
            setQuery(objToQuery(newQuery));
            const nQuery = objToQuery(newQuery);
            history.push(`?${nQuery}`);
            loadRows(nQuery);

            if (window.screen.width < 900) {
                setToggleSearch(false);
            }
        }
    };

    const handleOnKeyPress = (data: any) => {
        handleByOnKeyPressEnterNewQuery(data, false);
    };

    const QUERY_DEFAULT = `page=1&limit=${rowsPerPageOptions[0]}`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'text',
            name: 'user.nameDefault',
            variant: 'standard',
            control: control,
            label: 'Pesquisar pelo nome',
            size: 'small',
            handleOnKeyPress: (data: any) => {
                handleByOnKeyPressEnterNewQuery(data, true);
            },
            fullWidth: true,
        },
        {
            typeInput: 'text',
            name: 'user.name',
            variant: 'standard',
            control: control,
            label: 'Nome',
            handleOnKeyPress,
        },
    ];

    const [, ...handleInputs] = inputsFilter;

    const clearInputsFilter = () => {
        setValue('user.name', '');
        setValue('user.nameDefault', '');
    };

    const clearFilters = () => {
        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            'user.name': null,
            'user.nameDefault': null,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);
    };

    const handleClearFilters = (): void => {
        clearInputsFilter();
        clearFilters();
    };

    const handleEdit = (id: string) => {
        history.push(`${paths.userRegister}/${id}`);
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`user/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const emptyRows = () => {
        if (rowsPerPage > rows.length) {
            return rowsPerPage - rows.length;
        }
        return 0;
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Usuários
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={2}
                                direction="row"
                                width={'100%'}
                                justifyContent="space-between"
                                alignItems="flex-end">
                                {/* <Controller
                                    name={'user.nameDefault'}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <TextField
                                            size="small"
                                            onChange={event => {
                                                onChange(event);
                                            }}
                                            onKeyPress={(data: any) => {
                                                handleByOnKeyPressEnterNewQuery(
                                                    data,
                                                    true,
                                                );
                                            }}
                                            value={value}
                                            fullWidth
                                            label="Pesquisa pelo nome"
                                            variant="standard"
                                        />
                                    )}
                                /> */}
                                <FormInputText
                                    name={inputsFilter[0].name}
                                    control={inputsFilter[0].control}
                                    label={inputsFilter[0].label}
                                    size={inputsFilter[0].size}
                                    variant={inputsFilter[0].variant}
                                    handleOnKeyPress={
                                        inputsFilter[0].handleOnKeyPress
                                    }
                                />
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() => history.push(paths.userRegister)}
                        />
                    </Typography>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'normal'
                                            }>
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}>
                                            <TableCell
                                                scope="row"
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.email}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.active
                                                    ? 'Ativo'
                                                    : 'Inativo'}
                                            </TableCell>

                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() =>
                                                        handleEdit(row.id)
                                                    }>
                                                    <ModeEdit />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        setIdDelete(row.id);
                                                        setOpenModalDelete(
                                                            true,
                                                        );
                                                    }}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows() > 0 && (
                                    <>
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows(),
                                            }}>
                                            <TableCell colSpan={6}>
                                                {/* 1em é o mesmo tamanho do Icon svg */}
                                                <IconButton>
                                                    <div
                                                        style={{
                                                            width: '1em',
                                                            height: '1em',
                                                        }}></div>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            {toggleSearch && (
                <FilterSearch
                    inputs={inputsFilter}
                    setToggleSearch={setToggleSearch}
                    toggleSearch={toggleSearch}
                    setRowsPerPage={setRowsPerPage}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    implementationLoadRows={loadRows}
                    updateRows={deleteRegister}
                    setUpdateRows={setDeleteRegister}
                    queryDefault={QUERY_DEFAULT}
                    handleInputs={handleInputs}
                />
            )}
        </div>
    );
};

export default UserList;
