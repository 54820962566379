export const message = {
    error: {
        delete: 'Erro ao excluír registro',
        deleteAllOrOne: 'Erro ao exluir registro(s)',
        selectAll: 'Erro ao selecionar registros',
        selectOne: 'Erro ao selecionar registro',
        save: 'Erro ao salvar registro',
        login: 'Erro ao fazer login',
        downloadPdf: 'Erro ao baixar arquivo',
    },
    success: {
        delete: 'Registro excluído com sucesso',
        deleteAllOrOne: 'Registro(s) excluído(s) com sucesso',
        selectAll: '',
        selectOne: '',
        save: 'Registro salvo com sucesso',
    },
    warn: {
        save: 'A regra para salvar o registro não foi atendida',
    },
};

export function handleMessageError(
    messageError?: (errors: any, field: any) => string,
    messagesError?: any[],
    errors?: any,
    name?: any,
) {
    if (messageError && errors) {
        return messageError(errors, name);
    }

    if (messagesError && errors) {
        return messagesError.find(m => m.type == errors.type)?.message;
    }

    if (errors && errors.type === 'validate') {
        return errors.message;
    }
}
