import MainContainer from '../main-container';
import Form from './permissionForm';
import List from './permissionList';

const Permission: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const PermissionList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Permission, PermissionList };
