import MainContainer from '../main-container';
import Form from './additionalExpenseForm';
import List from './additionalExpenseList';

const AdditionalExpense: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const AdditionalExpenseList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { AdditionalExpense, AdditionalExpenseList };
