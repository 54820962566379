import axios from 'axios';
import { urls } from '../config';

const baseURL =
    process.env.REACT_APP_ENVIROMENT === 'local'
        ? urls.api.local
        : urls.api.prod;

const api = axios.create({
    baseURL,
});

api.interceptors.request.use(async config => {
    return config;
});
api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        if (error.response.status === 401) {
            localStorage.removeItem('@GESSIS:token');
            localStorage.removeItem('@GESSIS:openCollapsePermissions');

            if (!localStorage.getItem('@GESSIS:refreshToken')) {
                setTimeout(() => window.location.reload(), 500);
                return {
                    data: { data: [], token: null },
                };
            }

            try {
                const responseRefreshToken = await axios.post(
                    `${baseURL}/auth/refresh-token`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                '@GESSIS:refreshToken',
                            )}`,
                        },
                    },
                );
                let {
                    data: { token: newToken, refreshToken: newRefreshToken },
                } = responseRefreshToken;

                if (!newToken || !newRefreshToken) {
                    setTimeout(() => window.location.reload(), 500);
                    return {
                        data: { data: [], token: null },
                    };
                }

                localStorage.setItem('@GESSIS:refreshToken', newRefreshToken);
                localStorage.setItem('@GESSIS:token', newToken);
                api.defaults.headers.common[
                    'Authorization'
                ] = `Bearer ${newToken}`;
                const method: 'get' | 'put' | 'patch' | 'delete' | 'post' =
                    error.config.method;

                let data = undefined;
                if (typeof error.config.data == 'string') {
                    data = JSON.parse(error.config.data);
                }
                return api[method](error.config.url, data);
            } catch (er) {
                setTimeout(() => window.location.reload(), 500);
                return {
                    data: { data: [], token: null },
                };
            }
        }
        return Promise.reject(error);
    },
);

export default api;
