export const paths = {
    originType: '/records/origin-type',
    originTypeRegister: '/records/origin-type/register',
    product: '/records/product',
    productRegister: '/records/product/register',
    legalProcess: '/records/legal-process',
    legalProcessRegister: '/records/legal-process/register',
    additionalHonorarium: '/records/additional-honorarium',
    additionalHonorariumRegister: '/records/additional-honorarium/register',
    outputProduct: '/records/output-product',
    outputProductRegister: '/records/output-product/register',
    workOrder: '/records/work-order',
    workOrderRegister: '/records/work-order/register',
    provider: '/records/provider',
    providerRegister: '/records/provider/register',
    customer: '/records/customer',
    customerRegister: '/records/customer/register',
    user: '/records/user',
    userRegister: '/records/user/register',
    district: '/records/district',
    districtRegister: '/records/district/register',
    brand: '/records/brand',
    brandRegister: '/records/brand/register',
    modelBrand: '/records/model-brand',
    modelBrandRegister: '/records/model-brand/register',
    technicianService: '/records/technician-service',
    technicianServiceRegister: '/records/technician-service/register',
    configuration: '/records/configuration',
    configurationRegister: '/records/configuration/register',
    paid: '/paid',
    invoiceOutputProductRegister: '/invoice/output-product/register',
    invoiceOutputProduct: '/invoice/output-product',
    invoiceDetail: '/invoice-detail/payment',
    invoiceDetailList: '/invoice-detail/list',
    invoiceDetailRegister: '/invoice-detail/register',
    generatedInvoices: '/invoice-detail/generated-invoices',
    expenditurePortion: '/expenditure-portion/payment',
    expenditurePortionList: '/expenditure-portion/list',
    expenditurePortionRegister: '/expenditure-portion/register',
    generatedExpenditurePortions: '/expenditure-portion/generated-invoices',
    additionalExpense: '/records/additional-expense',
    additionalExpenseRegister: '/records/additional-expense/register',
    permission: '/records/permission',
    permissionRegister: '/records/permission/register',
    right: '/records/right',
    rightRegister: '/records/right/register',
    role: '/records/role',
    roleRegister: '/records/role/register',
    originExpenditure: '/records/originExpenditure',
    originExpenditureRegister: '/records/originExpenditure/register',
    withdrawn: '/records/withdrawn',
    withdrawnRegister: '/records/withdrawn/register',
    company: '/records/company',
    companyRegister: '/records/company/register',
};

export const rowsPerPageOptions = [15, 50, 100];

export const urls = {
    api: {
        local: 'http://localhost:6002',
        prod: 'https://advogados-api.gessis.com.br',
    },
    apiNfe: {
        local: 'http://localhost:8080',
        prod: 'http://localhost:8080',
    },
};

export const texts = {
    nameSystem: 'GESSIS - Gestão com sistema',
    info: 'Gerenciamento de processos judiciais, e faturamentos',
};
