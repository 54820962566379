import MainContainer from '../main-container';
import Form from './additionalHonorariumForm';
import List from './additionalHonorariumList';

const AdditionalHonorarium: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const AdditionalHonorariumList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { AdditionalHonorarium, AdditionalHonorariumList };
