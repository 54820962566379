import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Delete, ModeEdit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../util/query';
import './styles.scss';
import { TableGeneric } from '../../componets/table';
import { ColumnDef } from '@tanstack/react-table';
import FilterSearchComponent from '../../componets/filterComponent/filterSearch';
import { useAuth } from '../../context/AuthContext';
import { FormInputText } from '../../componets/form/formInputText';
import QueryStringGlobal from '../../componets/queryStringGlobal';
import { IconComponent, icons } from '../../componets/icons';
import { IFormInputPropsFilter } from '../../componets/interfaces/formInputPropsFilter';

interface Data {
    description?: string;
    id: string;
}

interface IDataQuery {
    description: string;
}

const defaultValues = {};

const ProductList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const { control, getValues, setValue } = useForm<IDataQuery>({
        defaultValues,
    });

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            const response = await api.get(`origin-type?${queryString}`);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: messageResponse,
            });
            setOpenLoading(false);
        }
    };

    const handleClick = (id: string) => {
        handleEdit(id);
    };

    const initialValuesMemo = useMemo(
        () => [
            {
                limit: rowsPerPageOptions[0],
                page: 1,
            },
        ],
        [],
    );

    const initialValues = {
        ...initialValuesMemo[0],
    };

    const valuesToBeHeart = [
        {
            keyParam: 'page',
            defaultValue: initialValues.page,
            implementation: (value: any) => {},
            implementationClear: () => {
                setPage(initialValues.page);
            },
        },
        {
            keyParam: 'limit',
            defaultValue: initialValues.limit,
            implementation: (value: any) => {},
            implementationClear: () => {
                setRowsPerPage(initialValues.limit);
            },
        },
    ];

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        defaultFilter: boolean | undefined,
    ) => {
        const queryObj = queryToObj(search.replace('?', ''));

        const newQuery = {
            ...queryObj,
            description: getValues('description'),
            page: 1,
        };
        const nQuery = objToQuery(newQuery);
        history.push(`?${nQuery}`);
        loadRows(nQuery);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery(false);
        }
    };

    const handleEdit = (id: string) => {
        history.push(`${paths.originTypeRegister}/${id}`);
    };
    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`origin-type/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const columnsTable = React.useMemo<ColumnDef<Data>[]>(
        () => [
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {row.original?.description}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleEdit(row.original.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIdDelete(row.original.id);
                                setOpenModalDelete(true);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const QUERY_DEFAULT = `page=1&limit=${rowsPerPageOptions[0]}`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: control,
            label: 'Descrição',
            setValue,
            size: 'small',
            variant: 'standard',
            handleOnKeyPress: (data: any) => {
                if (data.charCode === 13) {
                    handleByOnKeyPressEnterNewQuery(true);
                }
            },
        },
    ];

    return (
        <div className="principal-container-origin-type">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Tipos de origens
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={1}
                                direction="row"
                                width={'100%'}
                                justifyContent="space-between"
                                alignItems="flex-end">
                                <FormInputText
                                    name={inputsFilter[0].name}
                                    control={inputsFilter[0].control}
                                    label={inputsFilter[0].label}
                                    size={inputsFilter[0].size}
                                    variant={inputsFilter[0].variant}
                                    handleOnKeyPress={
                                        inputsFilter[0].handleOnKeyPress
                                    }
                                />
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() =>
                                history.push(paths.originTypeRegister)
                            }
                        />
                    </Typography>
                    <TableGeneric
                        data={rows}
                        columns={columnsTable}
                        minWidth={650}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            <QueryStringGlobal
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                page={page}
                implementationLoadRows={loadRows}
                updateRows={deleteRegister}
                setUpdateRows={setDeleteRegister}
                queryDefault={QUERY_DEFAULT}
                inputs={inputsFilter}
            />
        </div>
    );
};

export default ProductList;
