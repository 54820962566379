import React, { useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
} from '../../util/dateUtil';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../util/query';
import './styles.scss';
import { TableGeneric } from '../../componets/table';
import { IconComponent, icons } from '../../componets/icons';
import { ColumnDef } from '@tanstack/react-table';
import IName from '../../util/interfaces/name';
import IDescription from '../../util/interfaces/description';
import { useAuth } from '../../context/AuthContext';
import {
    GenericEnum,
    stateLegalProcessOptions,
} from '../../communs/enums/generic-enum';
import GlobalFilterSearchComponent from '../../componets/globalFilterComponent/filterSearch';
import { IFormInputPropsFilter } from '../../componets/interfaces/formInputPropsFilter';

interface Data {
    code: string;
    description?: string;
    user: IName;
    customer: IName;
    processNumber?: string;
    situation: '1' | '2' | '3' | '4' | '5';
    honoraryValue: number;
    dateHearing: Date;
    id: string;
}

interface IDataQuery {
    dateInitial?: Date | null;
    dateFinal?: Date | null;
    customer?: IName;
    user: IName[];
    situation: IDescription[];
    processNumber: string;
    code: string;
}

const defaultValues = {
    customer: {
        id: '',
        name: '',
    },
    user: [],
    situation: [],
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
    processNumber: '',
    code: '',
};

const instanceStateOptions = new GenericEnum(stateLegalProcessOptions);

const ProductList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [updateRows, setUpdateRows] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [options, setOptions] = useState<
        { id: string; description: string }[]
    >([]);

    const [optionsUsers, setOptionsUsers] = useState<
        { id: string; description: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();
    const { user } = useAuth();

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm<IDataQuery>({
        defaultValues,
    });

    useEffect(() => {
        async function loadUsers() {
            try {
                let queryAdd = '';

                if (user && !user.permissions['legal_process_execute']) {
                    queryAdd = `?user.id=${user.sub}`;
                }
                const response = await api.get(`users${queryAdd}`);
                const users = response.data.data.map((u: any) => ({
                    id: u.id,
                    description: u.name,
                }));

                const findUser = users.find((u: IName) => u.id == user.sub);
                setValue('user', [findUser]);

                setOptionsUsers(users);
            } catch (error) {
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messageResponse,
                });
                setOpenLoading(false);
            }
        }
        loadUsers();
    }, []);

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            const response = await api.get(`legal-process?${queryString}`);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (updateRows) {
                setUpdateRows(false);
            }
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: messageResponse,
            });
            setOpenLoading(false);
        }
    };

    const handleClick = (id: string) => {
        handleEdit(id);
    };

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        defaultFilter: boolean | undefined,
    ) => {
        if (getFieldState('dateInitial').invalid && getValues('dateInitial')) {
            return;
        }
        if (getFieldState('dateFinal').invalid && getValues('dateFinal')) {
            return;
        }

        const queryObj = queryToObj(search.replace('?', ''));

        let situation = '';
        if (getValues('situation')?.length > 0) {
            situation = getValues('situation')
                .map((o: any) => o.id)
                .join(',');
        }
        let user = '';
        if (getValues('user')?.length > 0) {
            user = getValues('user')
                .map((o: any) => o.id)
                .join(',');
        }

        const newQuery = {
            ...queryObj,
            'legalProcess.processNumber': getValues('processNumber'),
            'legalProcess.code': getValues('code'),
            situation: situation,
            'customer.id': getValues('customer')?.id || '',
            user: user,
            dateInitial: getValues('dateInitial')
                ? momentZoneToUnix(getValues('dateInitial'))
                : null,
            dateFinal: getValues('dateFinal')
                ? momentZoneToUnix(getValues('dateFinal'))
                : null,
            page: 1,
        };

        const query = objToQuery(newQuery);
        history.push(`?${query}`);
        loadRows(query);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const handleClearDates = () => {
        let queryT = search.replace('?', '');
        const objQuery = queryToObj(queryT);

        setTimeout(() => {
            setValue('dateInitial', null);
            setValue('dateFinal', null);
        }, 200);

        delete objQuery.dateInitial;
        delete objQuery.dateFinal;

        queryT = objToQuery(objQuery);
        history.push(`?${queryT}`);
        setUpdateRows(true);
        loadRows(queryT);
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery(false);
        }
    };

    const handleChangeInput = (data: any, input: string) => {
        handleByOnKeyPressEnterNewQuery(false);
    };

    const onChangeInputMultiple = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        handleByOnKeyPressEnterNewQuery(false);
    };

    const updateAutocompleteCustomer = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`customer?name=${description}`);
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeCustomer = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCustomer(event.target.value);
        }

        if (event.type === 'click' || event.type === 'keydown') {
            handleByOnKeyPressEnterNewQuery(false);
        }
    };

    const QUERY_DEFAULT = `page=1&limit=${
        rowsPerPageOptions[0]
    }&dateInitial=${momentZoneToUnix(
        defaultValues.dateInitial,
    )}&dateFinal=${momentZoneToUnix(defaultValues.dateFinal)}&user=${user.sub}`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'date',
            name: 'dateInitial',
            control: control,
            label: 'Data de início',
            handleChange: (data: any) => handleChangeInput(data, 'dateInitial'),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'date',
            name: 'dateFinal',
            control: control,
            label: 'Data de fim',
            handleChange: (data: any) => handleChangeInput(data, 'dateFinal'),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'text',
            name: 'code',
            variant: 'standard',
            control: control,
            label: 'Código',
            handleOnKeyPress,
            setValue,
            aliasToQuery: 'legalProcess.code',
        },
        {
            typeInput: 'text',
            name: 'processNumber',
            variant: 'standard',
            control: control,
            label: 'Número do processo',
            handleOnKeyPress,
            setValue,
        },
        {
            typeInput: 'multiple',
            name: 'situation',
            variant: 'standard',
            control: control,
            label: 'Situação',
            handleOnKeyPress,
            options: instanceStateOptions.optionsList(),
            setValue: setValue,
            getValues: getValues,
            handleChange: onChangeInputMultiple,
        },
        {
            typeInput: 'autocomplete',
            name: 'customer',
            variant: 'standard',
            control: control,
            label: 'Cliente',
            handleOnKeyPress,
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: options,
            handleChange: onChangeCustomer,
            entityPath: 'customer',
            aliasToQuery: 'customer.id',
            activeDebounce: true,
        },
        {
            typeInput: 'multiple',
            name: 'user',
            variant: 'standard',
            control: control,
            label: 'Advogado',
            handleOnKeyPress,
            options: optionsUsers,
            setValue: setValue,
            getValues: getValues,
            handleChange: onChangeInputMultiple,
            entityPath: 'users',
            keyQuery: 'user.id',
            activeDebounce: true,
        },
    ];

    const handleEdit = (id: string) => {
        history.push(`${paths.legalProcessRegister}/${id}`);
    };
    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`legal-process/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setUpdateRows(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const columnsTable = React.useMemo<ColumnDef<Data>[]>(
        () => [
            {
                id: 'code',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Código
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {row.original?.code}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Número processo
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {row.original?.processNumber}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'customer',
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() => handleClick(cell.row.original.id)}>
                            {cell.row.original.customer.name}
                        </TableCell>
                    );
                },
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Cliente
                    </TableCell>
                ),
            },
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Advogado
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {row.original?.user?.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'situation',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Situação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            key={row.id}
                            align="left"
                            onClick={() => handleClick(row.original.id)}>
                            {
                                instanceStateOptions.getObject(
                                    row.original.situation,
                                )?.description
                            }
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'dateHearing',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleClick(cell.row.original.id)}>
                        {cell?.row.original.dateHearing
                            ? formatDateWithoutHours(
                                  cell?.row.original?.dateHearing,
                              )
                            : ''}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data audiência
                    </TableCell>
                ),
            },
            {
                accessorKey: 'createAt',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleClick(cell.row.original.id)}>
                        {formatDateWithoutHours(cell?.row.original?.createAt)}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Criado em
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleEdit(row.original.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIdDelete(row.original.id);
                                setOpenModalDelete(true);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    return (
        <div className="principal-container-legal-process">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Processos
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={1}
                                direction="row"
                                width={'100%'}
                                justifyContent="space-between"
                                alignItems="flex-end">
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[0].name}
                                            control={inputsFilter[0].control}
                                            rules={null}
                                            label={inputsFilter[0].label}
                                            setValue={inputsFilter[0].setValue}
                                            fullWidth={
                                                inputsFilter[0].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[0].setError}
                                            clearErrors={
                                                inputsFilter[0].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[0].handleChange
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[1].name}
                                            control={inputsFilter[1].control}
                                            rules={null}
                                            label={inputsFilter[1].label}
                                            setValue={inputsFilter[1].setValue}
                                            fullWidth={
                                                inputsFilter[1].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[1].setError}
                                            clearErrors={
                                                inputsFilter[1].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[1].handleChange
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Tooltip
                                    title="Limpar filtro de datas"
                                    onClick={() => handleClearDates()}>
                                    <IconButton>
                                        <IconComponent icon={icons.clearAll} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() =>
                                history.push(paths.legalProcessRegister)
                            }
                        />
                    </Typography>
                    <TableGeneric
                        data={rows}
                        columns={columnsTable}
                        minWidth={650}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            <GlobalFilterSearchComponent
                inputs={inputsFilter}
                setToggleSearch={setToggleSearch}
                toggleSearch={toggleSearch}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                page={page}
                implementationLoadRows={loadRows}
                updateRows={updateRows}
                setUpdateRows={setUpdateRows}
                queryDefault={QUERY_DEFAULT}
            />
        </div>
    );
};

export default ProductList;
