import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { handleMessageError } from '../../util/handleMessages';
import { useMemo } from 'react';
import { debounce } from 'lodash';

export interface IFormInputProps {
    variant?: 'outlined' | 'standard' | 'filled' | undefined;
    name: string;
    control: any;
    rules?: any;
    label: string;
    messageError?: (errors: any, field: any) => string;
    handleChange?: any;
    handleOnKeyPress?: (data: any) => void;
    handleOnBlur?: (data: any) => void;
    options: {
        description: string;
        id: string;
    }[];
    setValue: any;
    getValues?: (name: string) => any | any[] | undefined;
    readOnly?: boolean;
    messagesError?: { type: string; message: string }[];
    activeDebounce?: boolean;
}

export const FormAutocompleteMultiple = ({
    variant,
    name,
    control,
    rules,
    label,
    messageError,
    options,
    setValue,
    getValues,
    handleChange,
    readOnly,
    messagesError,
    activeDebounce,
}: IFormInputProps) => {
    if (!variant) {
        variant = 'outlined';
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange && handleChange(event);
    };

    const debouncedChangeHandler = useMemo(
        () => debounce(handleOnChange, activeDebounce ? 700 : 0),
        [],
    );

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
            }) => {
                if (!value) {
                    value = [];
                }
                return (
                    <Autocomplete
                        multiple
                        options={options}
                        readOnly={readOnly}
                        getOptionDisabled={(option: any) =>
                            value.find((o: any) => o.id == option.id)?.id
                                ? true
                                : false
                        }
                        onChange={(event, option) => {
                            // const lastOption = option[option.length - 1];
                            // const findOptions = option.find(
                            //     (o, index) =>
                            //         index != option.length - 1 &&
                            //         o.description === lastOption.description,
                            // );
                            // if (findOptions) {
                            //     option.splice(option.length - 1, 1);
                            // }
                            onChange(event);
                            setValue(name, [...option]);
                            handleChange && handleChange(event, option);
                        }}
                        value={
                            value
                                ? options.filter(o =>
                                      value
                                          .map((v: any) => v.id)
                                          .includes(o.id),
                                  )
                                : []
                        }
                        getOptionLabel={option => option.description}
                        size="small"
                        noOptionsText="Nenhum registro encontrado"
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={variant}
                                size="small"
                                label={label}
                                helperText={handleMessageError(
                                    messageError,
                                    messagesError,
                                    error,
                                    name,
                                )}
                                onChange={debouncedChangeHandler}
                                inputProps={{
                                    ...params.inputProps,
                                }}
                                error={!!error}
                            />
                        )}
                    />
                );
            }}
        />
    );
};
