import {
    Box,
    Grid,
    Paper,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import AlertDialog from '../../../componets/dialog/alertDialog';
import { FormButton } from '../../../componets/form/formButton';
import { paths } from '../../../config';
import { useContextGlobal } from '../../../context/ContextGlobal';
import { useToast } from '../../../context/ToastContext';
import api from '../../../services/api';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../../util/handleExceptionAxios';
import { message } from '../../../util/handleMessages';
import { GenericEnum, monthOptions } from '../../../communs/enums/generic-enum';
import { FormInputProps } from '../../../componets/form/formInterfaces';
import IDescription from '../../../util/interfaces/description';
import { useAuth } from '../../../context/AuthContext';
import RenderForm from '../../../componets/form/renderForm';
import { format, subMonths } from 'date-fns';

interface IFormInput {
    year?: string;
    month?: IDescription;
    totalReceive: number;
    withdrawn: number;
    balanceNextMonth?: number;
}

const defaultValues = {
    year: '',
    month: undefined,
    totalReceive: 0,
    withdrawn: 0,
};

const instanceMonthOptions = new GenericEnum(monthOptions);

const settings = {
    pathRegister: paths.withdrawnRegister,
    pathList: paths.withdrawn,
    apiPath: 'withdrawn',
    permissionAdmin: 'withdrawn',
    tittle: 'Fechamento de caixa',
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const { handleSubmit, control, reset, setValue, getValues, watch } =
        useForm<IFormInput>({
            defaultValues,
        });

    useEffect(() => {
        if (!params?.id) {
            setTimeout(() => {
                setValue('year', getActualYear());
                setValue('month', getPreviousMonth());
            }, 100);
        }
    }, []);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`${settings.apiPath}/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    useEffect(() => {
        if (!params.id) {
            loadBalance();
        }
    }, [watch('month')]);

    const loadBalance = useCallback(async () => {
        if (getValues('month')?.id && getValues('year')) {
            const response = await api.get(
                `${settings.apiPath}/suggested-registration?month=${
                    getValues('month')?.id
                }&year=${getValues('year')}`,
            );

            setValue('totalReceive', response.data.balance);
        }
    }, []);

    const getActualYear = () => {
        return format(new Date(), 'yyyy');
    };

    const getPreviousMonth = () => {
        const previousMonth = subMonths(new Date(), 1);
        const monthObj = instanceMonthOptions.getObject(
            format(previousMonth, 'MM'),
        );
        return monthObj;
    };

    const onChange = (value: number) => {
        setValue('balanceNextMonth', getValues('totalReceive') - value);
    };

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'year',
            control: control,
            label: 'Ano',
            md: 2,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo ano é obrigatório',
                },
            ],
            readOnly: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'month',
            control: control,
            label: 'Mês',
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: instanceMonthOptions.optionsList(),
            md: 2,
            xs: 12,
            readOnly: true,
            dto: data => {
                data.month = `${data.year}-${data.month.id}`;
                return data;
            },
            activeDebounce: true,
        },
        {
            typeInput: 'number',
            name: 'totalReceive',
            control: control,
            label: 'Total disponível para retirada',
            md: 2,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo total disponível é obrigatório',
                },
            ],
        },
        {
            typeInput: 'number',
            name: 'withdrawn',
            control: control,
            label: 'Retirado',
            md: 2,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo retirado é obrigatório',
                },
            ],
            handleChange: onChange,
        },
        {
            typeInput: 'number',
            name: 'balanceNextMonth',
            control: control,
            label: 'Saldo para o próximo mês',
            md: 2,
            xs: 12,
            decimalScale: 2,
            dto: data => {
                if (!data.withdrawn) {
                    data.balanceNextMonth = 0;
                }
                return data;
            },
        },
    ];

    const setModel = (data: any) => {
        const [year, month] = data.month.split('-');
        const monthObj = instanceMonthOptions.getObject(month);
        setValue('month', monthObj);
        setValue('year', year);
        setValue('totalReceive', data.totalReceive);
        setValue('withdrawn', data.withdrawn);
    };

    const submit = async (data: any, e: any) => {
        e.preventDefault();
        try {
            setOpenLoading(true);

            for (const input of inputsForm) {
                if (!input?.dto) {
                    continue;
                }
                data = input.dto(data);
            }

            let response = { data: { id: null } };
            if (params && params.id) {
                response = await api.patch(
                    `${settings.apiPath}/${params.id}`,
                    data,
                );
            } else {
                response = await api.post(settings.apiPath, data);
            }
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            reset(defaultValues);

            if (data.generateInstallments) {
                history.push(
                    `${paths.expenditurePortionRegister}/${response.data.id}/additionalExpense`,
                );
            } else {
                history.goBack();
            }
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`${settings.apiPath}/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.legalProcess}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(submit)}>
                    <Grid container spacing={2}>
                        <RenderForm inputsForm={inputsForm} />
                    </Grid>
                    <br />

                    <div className="info-footer">
                        <div className="footer-buttons">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            {params.id && (
                                <FormButton
                                    label={'Excluir'}
                                    typeButton={'delete'}
                                    onClick={() => setOpenModalDelete(true)}
                                />
                            )}
                            <FormButton
                                label={'Voltar'}
                                typeButton={'cancel'}
                                onClick={() => handleCancel()}
                            />
                        </div>
                    </div>
                </form>
            </Paper>
        </div>
    );
};

export default Form;
