import React from 'react';
import logoImg from '../../assets/images/40635.svg';
import MainContainer from '../main-container';
import './styles.scss';
import { texts } from '../../config';

const Component: React.FC = () => {
    return (
        <div className="principal-container-dashboard">
            <div className="container-title">
                <h2>{texts.nameSystem}</h2>
                <h6>{texts.info}</h6>
            </div>
            <div className="container-image">
                <img src={logoImg} alt="Gessis" />
            </div>
        </div>
    );
};

const Dashboard: React.FC = () => {
    return (
        <MainContainer>
            <Component />
        </MainContainer>
    );
};

export default Dashboard;
