import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { TableGeneric } from '../../componets/table';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../util/query';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
    unixToDate,
} from '../../util/dateUtil';
import './styles.scss';
import { IconComponent, icons } from '../../componets/icons';
import { formatNumber } from '../../util/infoFormat';
import {
    GenericEnum,
    typeQueryOptions,
} from '../../communs/enums/generic-enum';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import IDescription from '../../util/interfaces/description';
import IName from '../../util/interfaces/name';
import FilterSearchComponent from '../../componets/filterComponent/filterSearch';
import { useAuth } from '../../context/AuthContext';
import { IFormInputPropsFilter } from '../../componets/interfaces/formInputPropsFilter';

interface IInvoiceDetail {
    formPayment: string;
    typePayment: string;
    description: string;
    dueDate: Date;
    installmentValue: number;
    downloaded: boolean;
    legalProcess?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
    additionalHonorarium?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
    additionalExpense?: {
        id: string;
        code: string;
        customer?: {
            id: string;
            name: string;
            identification: string;
        };
    };
}

interface IDataQuery {
    dateInitial?: Date;
    dateFinal?: Date;
    customer?: IName;
    user: IName[];
    typeQuery: IDescription;
    processNumber: string;
    code: string;
}

const defaultValues = {
    code: '',
    processNumber: '',
    customer: {
        id: '',
        name: '',
    },
    typeQuery: {
        id: 'legalProcess',
        description: 'Processos',
    },
    user: [],
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
};

const typeQueryInstance = new GenericEnum(typeQueryOptions);
const typeQueryEnum = typeQueryInstance.optionsList();

const settings = {
    pathRegister: paths.invoiceDetailRegister,
    pathList: paths.invoiceDetailList,
    apiPath: 'invoice-detail',
    permissionAdmin: 'invoice_detail_execute',
    tittle: 'Faturas geradas',
};

const ListEntity: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<IInvoiceDetail[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [options, setOptions] = useState<
        { id: string; description: string }[]
    >([]);
    const [optionsUsers, setOptionsUsers] = useState<
        { id: string; description: string }[]
    >([]);
    const { user } = useAuth();
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm<IDataQuery>({
        defaultValues,
    });

    useEffect(() => {
        async function loadUsers() {
            try {
                let queryAdd = '';

                if (user && !user.permissions[settings.permissionAdmin]) {
                    queryAdd = `?user.id=${user.sub}`;
                }
                const response = await api.get(`users${queryAdd}`);
                const users = response.data.data.map((u: any) => ({
                    id: u.id,
                    description: u.name,
                }));

                const findUser = users.find((u: IName) => u.id == user.sub);
                setValue('user', [findUser]);

                setOptionsUsers(users);
            } catch (error) {
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: '',
                });
                setOpenLoading(false);
            }
        }
        loadUsers();
    }, []);

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            const queryObj = queryToObj(queryString);
            const typeQuery = queryObj?.typeQuery;
            delete queryObj.typeQuery;
            const newQueryString = objToQuery(queryObj);

            let path = `additional-honorarium/with-invoices?${newQueryString}`;
            if (typeQuery === 'legalProcess') {
                path = `legal-process/with-invoices?${newQueryString}`;
            }
            if (typeQuery === 'additionalExpense') {
                path = `additional-expense/with-invoices?${newQueryString}`;
            }
            const response = await api.get(path);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: messageResponse,
            });
            setOpenLoading(false);
        }
    };

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = () => {
        if (getFieldState('dateInitial').invalid) {
            return;
        }
        if (getFieldState('dateFinal').invalid) {
            return;
        }

        let user = '';
        if (getValues('user')?.length > 0) {
            user = getValues('user')
                .map((o: any) => o.id)
                .join(',');
        }

        const queryObj = queryToObj(search.replace('?', ''));
        const newQuery = {
            ...queryObj,
            code: getValues('code'),
            processNumber: getValues('processNumber'),
            'customer.id': getValues('customer')?.id || '',
            user: user,
            dateInitial: momentZoneToUnix(getValues('dateInitial')),
            dateFinal: momentZoneToUnix(getValues('dateFinal')),
            typeQuery: getValues('typeQuery')?.id || '',
            page: 1,
        };
        const nQuery = objToQuery(newQuery);
        history.push(`?${nQuery}`);
        loadRows(nQuery);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const columnsDetPag = React.useMemo<ColumnDef<IInvoiceDetail>[]>(
        () => [
            {
                accessorKey: 'date',
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() =>
                                handleClick(
                                    cell.row.original.id,
                                    cell.row.original.typeQuery,
                                    cell.row.original.user.id,
                                )
                            }>
                            {formatDateWithoutHours(cell?.getValue())}
                        </TableCell>
                    );
                },
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Código / Número
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                    row.original.user.id,
                                )
                            }>
                            {row.original?.processNumber || row.original?.code}
                        </TableCell>
                    );
                },
            },

            {
                id: 'select',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Cliente
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                    row.original.user.id,
                                )
                            }>
                            {row.original?.customer?.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Identificação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            key={row.id}
                            align="left"
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                    row.original.user.id,
                                )
                            }>
                            {row.original?.customer?.identification
                                ? cpfAndCnpjMask(
                                      row.original?.customer?.identification,
                                      false,
                                  )
                                : ''}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'user',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() =>
                            handleClick(
                                cell.row.original.id,
                                cell.row.original.typeQuery,
                                cell.row.original.user.id,
                            )
                        }>
                        {cell.row.original.user.name}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Advagado
                    </TableCell>
                ),
            },
            {
                accessorKey: 'total',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() =>
                            handleClick(
                                cell.row.original.id,
                                cell.row.original.typeQuery,
                                cell.row.original.user.id,
                            )
                        }>
                        {formatNumber(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <Tooltip
                            title="Gerar parcelas"
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                    row.original.user.id,
                                )
                            }>
                            <IconButton>
                                <IconComponent icon={icons.eventNote} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Deletar todas as parcelas geradas anteriormente"
                            onClick={() =>
                                handleDeleteAll(
                                    row.original.id,
                                    row.original.typeQuery,
                                )
                            }>
                            <IconButton>
                                <IconComponent icon={icons.delete} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const handleDeleteAll = async (id: string, typeQuery: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`invoice-detail/${id}/origin/${typeQuery}`);
            addToast({
                type: 'success',
                title: message.success.deleteAllOrOne,
                description: 'Parcelas excluídos',
            });
            setOpenLoading(false);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.deleteAllOrOne,
                description: messageResponse,
            });
        }
    };
    const handleClick = (id: string, typeQuery: string, userId: string) => {
        handleEdit(id, typeQuery, userId);
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const handleOnChange = () => {
        handleByOnKeyPressEnterNewQuery();
    };

    const initialValuesMemo = useMemo(
        () => [
            {
                limit: rowsPerPageOptions[0],
                page: 1,
                dateInitial: momentZoneToUnix(getValues('dateInitial')),
                dateFinal: momentZoneToUnix(getValues('dateFinal')),
                code: '',
                processNumber: '',
                'customer.id': undefined,
                typeQuery: 'legalProcess',
                user: [user.sub],
            },
        ],
        [user.sub],
    );

    const initialValues = {
        ...initialValuesMemo[0],
    };

    const valuesToBeHeart = [
        {
            keyParam: 'page',
            defaultValue: initialValues.page,
            implementation: (value: any) => {},
            implementationClear: () => {
                setPage(initialValues.page);
            },
        },
        {
            keyParam: 'limit',
            defaultValue: initialValues.limit,
            implementation: (value: any) => {},
            implementationClear: () => {
                setRowsPerPage(initialValues.limit);
            },
        },
        {
            keyParam: 'typeQuery',
            defaultValue: initialValues.typeQuery,
            implementation: (value: any) => {},
            implementationClear: () => {
                setValue('typeQuery', {
                    id: 'legalProcess',
                    description: 'Processos',
                });
            },
        },
        {
            keyParam: 'dateInitial',
            defaultValue: initialValues.dateInitial,
            implementation: (value: any) => {
                setValue('dateInitial', unixToDate(value));
            },
            implementationClear: () => {
                setValue('dateInitial', unixToDate(initialValues.dateInitial));
            },
        },
        {
            keyParam: 'dateFinal',
            defaultValue: initialValues.dateFinal,
            implementation: (value: any) => {
                setValue('dateFinal', unixToDate(value));
            },
            implementationClear: () => {
                setValue('dateFinal', unixToDate(initialValues.dateFinal));
                setValue('code', defaultValues.code);
                setValue('processNumber', defaultValues.processNumber);
                setValue('customer', defaultValues.customer);
            },
        },
    ];

    const updateAutocompleteCustomer = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`customer?name=${description}`);
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeCustomer = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCustomer(event.target.value);
        }

        if (event.type === 'click' || event.type === 'keydown') {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const onChangeOperation = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.type === 'click' || event.type === 'keydown') {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const onChangeInputMultiple = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        handleByOnKeyPressEnterNewQuery();
    };

    const QUERY_DEFAULT = `page=1&limit=${
        rowsPerPageOptions[0]
    }&dateInitial=${momentZoneToUnix(
        defaultValues.dateInitial,
    )}&dateFinal=${momentZoneToUnix(defaultValues.dateFinal)}&user=${
        user.sub
    }&typeQuery=legalProcess`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'date',
            name: 'dateInitial',
            control: control,
            label: 'Data de início',
            handleChange: (data: any) => handleOnChange(),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'date',
            name: 'dateFinal',
            control: control,
            label: 'Data de fim',
            handleChange: (data: any) => handleOnChange(),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'text',
            name: 'processNumber',
            variant: 'standard',
            control: control,
            label: 'Número do processo',
            handleOnKeyPress,
            setValue,
        },
        {
            typeInput: 'autocomplete',
            name: 'customer',
            variant: 'standard',
            control: control,
            label: 'Cliente',
            handleOnKeyPress,
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: options,
            handleChange: onChangeCustomer,
            activeDebounce: true,
        },
        {
            typeInput: 'multiple',
            name: 'user',
            variant: 'standard',
            control: control,
            label: 'Advogado',
            handleOnKeyPress,
            options: optionsUsers,
            setValue: setValue,
            getValues: getValues,
            handleChange: onChangeInputMultiple,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'typeQuery',
            variant: 'standard',
            control: control,
            loadingAutocomplete: false,
            setValue: setValue,
            label: 'Operação',
            options: typeQueryEnum,
            handleOnKeyPress,
            handleChange: onChangeOperation,
        },
        {
            typeInput: 'text',
            name: 'code',
            variant: 'standard',
            control: control,
            label: 'Código',
            handleOnKeyPress,
            setValue,
        },
    ];

    const handleEdit = (id: string, typeQuery: string, userId: string) => {
        const redirect = Object.keys(paths).find(
            k => k === 'generatedInvoices',
        );
        history.push(
            `${paths.invoiceDetailRegister}/${id}/${typeQuery}/${userId}/${redirect}`,
        );
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`provider/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }} component="div">
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                {settings.tittle}
                            </Typography>
                        </div>

                        <div className="header-list-search">
                            <Stack spacing={2} direction="row" width={'100%'}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[0].name}
                                            control={inputsFilter[0].control}
                                            rules={null}
                                            label={inputsFilter[0].label}
                                            setValue={inputsFilter[0].setValue}
                                            fullWidth={
                                                inputsFilter[0].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[0].setError}
                                            clearErrors={
                                                inputsFilter[0].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[0].handleChange
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[1].name}
                                            control={inputsFilter[1].control}
                                            rules={null}
                                            label={inputsFilter[1].label}
                                            setValue={inputsFilter[1].setValue}
                                            fullWidth={
                                                inputsFilter[1].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[1].setError}
                                            clearErrors={
                                                inputsFilter[1].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[1].handleChange
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <TableGeneric
                        data={rows}
                        columns={columnsDetPag}
                        minWidth={650}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            <FilterSearchComponent
                inputs={inputsFilter}
                setToggleSearch={setToggleSearch}
                toggleSearch={toggleSearch}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                page={page}
                implementationLoadRows={loadRows}
                updateRows={deleteRegister}
                setUpdateRows={setDeleteRegister}
                queryDefault={QUERY_DEFAULT}
            />
        </div>
    );
};

export default ListEntity;
