import FilterListIcon from '@mui/icons-material/FilterList';
import { CircularProgress, Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ColumnDef } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { TableGeneric } from '../../componets/table';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../util/query';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
    unixToDate,
} from '../../util/dateUtil';
import { IconComponent, icons } from '../../componets/icons';
import { formatNumber } from '../../util/infoFormat';
import {
    GenericEnum,
    typeQueryOperationOptions,
    yesOrNoOrBothOptions,
} from '../../communs/enums/generic-enum';
import './stylesPayment.scss';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import { colors } from '../../util/colors';
import { downloadReport } from '../../services/download-report';
import { useAuth } from '../../context/AuthContext';
import FilterSearchComponent from '../../componets/filterComponent/filterSearch';
import IName from '../../util/interfaces/name';
import IDescription from '../../util/interfaces/description';
import { IFormInputPropsFilter } from '../../componets/interfaces/formInputPropsFilter';

interface IItemHonorary {
    originType: {
        id: string;
        description: string;
    };
    description: string;
    quantity: number;
    itemValue: number;
}

interface IItemExpense {
    originExpenditure: {
        id: string;
        description: string;
    };
    description: string;
    quantity: number;
    itemValue: number;
}

interface IInvoiceDetail {
    formPayment: string;
    typePayment: string;
    description: string;
    dueDate: Date;
    installmentValue: number;
    downloaded: boolean;
    legalProcess?: {
        id: string;
        code: string;
        processNumber: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
        user: {
            id: string;
            name: string;
        };
        honoraryValue: number;
    };
    additionalHonorarium?: {
        id: string;
        code: string;
        processNumber: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
        user: {
            id: string;
            name: string;
        };
        itemHonoraries: IItemHonorary[];
    };
    additionalExpense?: {
        id: string;
        code: string;
        processNumber: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
        user: {
            id: string;
            name: string;
        };
        itemExpenses: IItemExpense[];
    };
}

interface IDataQuery {
    dateInitial?: Date;
    dateFinal?: Date;
    customer?: IName;
    user: IName[];
    processNumber: string;
    code: string;
    downloaded: IDescription;
    typeOperation: {
        // id: 'received' | 'payable' | 'all';
        id: string;
        description: string;
    };
}

const defaultValues = {
    code: '',
    processNumber: '',
    customer: {
        id: '',
        name: '',
    },
    downloaded: {
        id: '',
        description: '',
    },
    user: [],
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
};

const paramsModais = {
    titleConfirm: {
        writeOff: {
            message: 'Deseja dar baixa da fatura?',
        },
        unsubscribe: {
            message: 'Deseja cancelar a baixa da fatura?',
        },
    },
    titlePrint: 'Deseja imprimir o recibo?',
};

const yesOrNoOrBothInstance = new GenericEnum(yesOrNoOrBothOptions);
const yesOrNoOrBothEnum = yesOrNoOrBothInstance.optionsList();
const typeQueryOperationInstance = new GenericEnum(typeQueryOperationOptions);
const typeQueryOperationEnum = typeQueryOperationInstance.optionsList();

const settings = {
    pathRegister: paths.invoiceDetailRegister,
    pathList: paths.invoiceDetailList,
    apiPath: 'invoice-detail',
    permissionAdmin: 'invoice_detail_execute',
    title: 'Faturas',
};

const ListEntity: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<IInvoiceDetail[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const params = useParams<{ id?: string }>();
    const [openModalAction, setOpenModalAction] = useState(false);
    const [additionalParams, setAdditionalParams] = useState<any>({});
    const [openModalDownloadReport, setOpenModalDownloadReport] = useState<{
        open: boolean;
        id?: string;
    }>({ open: false, id: undefined });
    const [messageDialogTitle, setMessageDialogTitle] = useState<
        string | undefined
    >(undefined);
    const [messageDialogContent, setMessageDialogContent] = useState<
        string | undefined
    >(undefined);
    const [legendTotal, setLegendTotal] = useState<any>({});
    const [options, setOptions] = useState<
        { id: string; description: string }[]
    >([]);
    const [optionsUsers, setOptionsUsers] = useState<
        { id: string; description: string }[]
    >([]);

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();
    const { user } = useAuth();
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [loadingDownloadReport, setLoadingDownloadReport] = useState(false);

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm<IDataQuery>({
        defaultValues,
    });

    useEffect(() => {
        async function loadUsers() {
            try {
                let queryAdd = '';

                if (user && !user.permissions[settings.permissionAdmin]) {
                    queryAdd = `?user.id=${user.sub}`;
                }
                const response = await api.get(`users${queryAdd}`);
                const users = response.data.data.map((u: any) => ({
                    id: u.id,
                    description: u.name,
                }));

                const findUser = users.find((u: IName) => u.id == user.sub);
                if (findUser) {
                    setValue('user', [findUser]);
                }

                setOptionsUsers(users);
            } catch (error) {
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messageResponse,
                });
                setOpenLoading(false);
            }
        }
        loadUsers();
    }, []);

    // Removendo parâmetro modalAction
    const parametersOfQuery = (queryString: string) => {
        const queryUseInFind = queryToObj(queryString);
        if (queryUseInFind.action && queryUseInFind.invoiceId) {
            delete queryUseInFind.action;
            delete queryUseInFind.invoiceId;
        }
        const queryStringUseInFind = objToQuery(queryUseInFind);
        return queryStringUseInFind;
    };

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);

        const queryStringUseInFind = parametersOfQuery(queryString);
        try {
            const response = await api.get(
                `invoice-detail?${queryStringUseInFind}`,
            );
            setRows(response.data.data);
            setTotal(response.data.count);
            setAdditionalParams(response.data.additionalParams);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: messageResponse,
            });
            setOpenLoading(false);
        }
    };

    const plotDescription = (data: any) => {
        const operationInfo: any = {
            code: '',
            description: '',
        };

        if (data?.legalProcess) {
            operationInfo.code = data.legalProcess.processNumber;
            operationInfo.description = 'Processo';
        }

        if (data?.additionalHonorarium) {
            const origins = data.additionalHonorarium.itemHonoraries.map(
                (item: any) => item.originType.description,
            );
            operationInfo.code = origins.join(',');
            operationInfo.description = 'Honorários de';
        }

        if (data?.additionalExpense) {
            const origins = data.additionalExpense.itemExpenses.map(
                (item: any) => item.originExpenditure.description,
            );
            operationInfo.code = origins.join(',');
            operationInfo.description = 'Despesas de';
        }

        return operationInfo;
    };

    const loadInvoice = async ({
        id,
        action,
    }: {
        id: string;
        action: 'unsubscribe' | 'writeOff';
    }): Promise<void> => {
        setOpenLoading(true);

        try {
            const response = await api.get(`invoice-detail/${id}`);
            const data = response.data;

            const operationInfo = plotDescription(data);

            setMessageDialogContent(`
                                    <p style="margin: 0 0 8px 0;"><strong>Descrição da fatura:</strong> ${response.data.description}</p>
                                    <strong>Origem</strong>: ${operationInfo.description} ${operationInfo.code}`);

            setMessageDialogTitle(paramsModais.titleConfirm[action].message);
            setOpenModalAction(true);
            setOpenLoading(false);
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectOne,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = () => {
        if (getFieldState('dateInitial').invalid) {
            return;
        }
        if (getFieldState('dateFinal').invalid) {
            return;
        }

        let user = '';
        if (getValues('user')?.length > 0) {
            user = getValues('user')
                .map((o: any) => o.id)
                .join(',');
        }

        const queryObj = queryToObj(search.replace('?', ''));
        const newQuery = {
            ...queryObj,
            code: getValues('code'),
            processNumber: getValues('processNumber'),
            'customer.id': getValues('customer')?.id || '',
            user: user,
            dateInitial: momentZoneToUnix(getValues('dateInitial')),
            dateFinal: momentZoneToUnix(getValues('dateFinal')),
            downloaded: getValues('downloaded')?.id || '',
            typeOperation: getValues('typeOperation')?.id || '',
            page: 1,
        };
        const query = objToQuery(newQuery);
        history.push(`?${query}`);
        loadRows(query);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const initialValuesMemo = useMemo(
        () => [
            {
                limit: rowsPerPageOptions[0],
                page: 1,
                dateInitial: momentZoneToUnix(getValues('dateInitial')),
                dateFinal: momentZoneToUnix(getValues('dateFinal')),
                code: '',
                processNumber: '',
                'customer.id': undefined,
                user: [user.sub],
                downloaded: '0',
                typeOperation: 'received',
            },
        ],
        [user.sub],
    );

    useEffect(() => {
        if (getValues('typeOperation')) {
            const configLegendTotal = {
                received: {
                    received: `Recebido: ${formatNumber(
                        additionalParams?.received,
                    )}`,
                    toReceive: `À receber: ${formatNumber(
                        additionalParams?.toReceive,
                    )}`,
                    overdue: `Vencido: ${formatNumber(
                        additionalParams?.overdue,
                    )}`,
                    grandTotal: `Geral: ${formatNumber(
                        additionalParams?.grandTotal,
                    )}`,
                },
                payable: {
                    received: `Pago: ${formatNumber(
                        additionalParams?.received,
                    )}`,
                    toReceive: `À pagar: ${formatNumber(
                        additionalParams?.toReceive,
                    )}`,
                    overdue: `Vencido: ${formatNumber(
                        additionalParams?.overdue,
                    )}`,
                    grandTotal: `Geral: ${formatNumber(
                        additionalParams?.grandTotal,
                    )}`,
                },
                all: {
                    overdue: `Vencido: ${formatNumber(
                        additionalParams?.overdue,
                    )}`,
                    grandTotal: `Geral: ${formatNumber(
                        additionalParams?.grandTotal,
                    )}`,
                },
            };

            const result = getValues('typeOperation').id as
                | 'received'
                | 'payable'
                | 'all';
            setLegendTotal(configLegendTotal[result]);
        }
    }, [additionalParams]);

    const initialValues = {
        ...initialValuesMemo[0],
    };

    const valuesToBeHeart = [
        {
            keyParam: 'page',
            defaultValue: initialValues.page,
            implementation: (value: any) => {},
            implementationClear: () => {
                // setPage(initialValues.page);
            },
        },
        {
            keyParam: 'limit',
            defaultValue: initialValues.limit,
            implementation: (value: any) => {},
            implementationClear: () => {
                // setRowsPerPage(initialValues.limit);
            },
        },
        {
            keyParam: 'dateInitial',
            defaultValue: initialValues.dateInitial,
            implementation: (value: any) => {
                setValue('dateInitial', unixToDate(value));
            },
            implementationClear: () => {
                setValue('dateInitial', unixToDate(initialValues.dateInitial));
            },
        },
        {
            keyParam: 'downloaded',
            defaultValue: initialValues.downloaded,
            implementation: (value: any) => {
                setValue('downloaded', yesOrNoOrBothInstance.getObject(value));
            },
            implementationClear: () => {
                setValue(
                    'downloaded',
                    yesOrNoOrBothInstance.getObject(
                        String(initialValues.downloaded),
                    ),
                );
            },
        },
        {
            keyParam: 'typeOperation',
            defaultValue: initialValues.typeOperation,
            implementation: (value: any) => {
                setValue(
                    'typeOperation',
                    typeQueryOperationInstance.getObject(value),
                );
            },
            implementationClear: () => {
                setValue(
                    'typeOperation',
                    typeQueryOperationInstance.getObject(
                        String(initialValues.typeOperation),
                    ),
                );
            },
        },
        {
            keyParam: 'dateFinal',
            defaultValue: initialValues.dateFinal,
            implementation: (value: any) => {
                setValue('dateFinal', unixToDate(value));
            },
            implementationClear: () => {
                setValue('dateFinal', unixToDate(initialValues.dateFinal));
                setValue('code', defaultValues.code);
                setValue('processNumber', defaultValues.processNumber);
                setValue('customer', defaultValues.customer);
                setValue('downloaded', { id: '', description: '' });
                setValue('typeOperation', { id: '', description: '' });
            },
        },
    ];

    useEffect(() => {
        let queryT = '';

        if (
            window.location.search &&
            window.location.search.length > 0 &&
            (!params || !params.id)
        ) {
            queryT = window.location.search.replace('?', '');
        }
        const queryToObjParams = queryToObj(queryT);
        if (queryToObjParams?.action && queryToObjParams?.invoiceId) {
            loadInvoice({
                id: queryToObjParams.invoiceId,
                action: queryToObjParams.action,
            });
        }
    }, [window.location.search]);

    const handleCodeOrProcessNumber = useCallback(
        (original: IInvoiceDetail) => {
            if (original?.additionalHonorarium?.processNumber) {
                return original.additionalHonorarium.processNumber;
            }
            if (original?.additionalHonorarium?.code) {
                return original.additionalHonorarium.code;
            }
            if (original?.legalProcess?.code) {
                return original.legalProcess.code;
            }
            if (original?.legalProcess?.processNumber) {
                return original.legalProcess.processNumber;
            }
            if (original?.additionalExpense?.processNumber) {
                return original.additionalExpense.processNumber;
            }
            if (original?.additionalExpense?.code) {
                return original.additionalExpense.code;
            }
            return '';
        },
        [],
    );

    const handleOrigin = useCallback((original: IInvoiceDetail) => {
        const dataPlot = plotDescription(original);
        if (dataPlot?.description) {
            const { code, description } = dataPlot;
            return `${description} ${code.toLowerCase()}`;
        }
        return '';
    }, []);

    const handleUser = useCallback((original: IInvoiceDetail) => {
        if (original?.additionalExpense?.user) {
            return original.additionalExpense.user.name;
        }
        if (original?.legalProcess?.user) {
            return original.legalProcess.user.name;
        }
        if (original?.additionalHonorarium?.user) {
            return original.additionalHonorarium.user.name;
        }
        return '';
    }, []);

    const handleCustomer = (original: IInvoiceDetail) => {
        if (original?.additionalExpense?.customer) {
            return original.additionalExpense.customer.name;
        }
        if (original?.legalProcess?.customer) {
            return original.legalProcess.customer.name;
        }
        if (original?.additionalHonorarium?.customer) {
            return original.additionalHonorarium.customer.name;
        }
        return '';
    };

    const handleIdentification = (original: IInvoiceDetail) => {
        if (original?.additionalExpense?.customer) {
            return cpfAndCnpjMask(
                original.additionalExpense?.customer?.identification,
                false,
            );
        }
        if (original?.legalProcess?.customer) {
            return cpfAndCnpjMask(
                original.legalProcess?.customer?.identification,
                false,
            );
        }
        if (original?.additionalHonorarium?.customer) {
            return cpfAndCnpjMask(
                original.additionalHonorarium?.customer?.identification,
                false,
            );
        }
        return '';
    };

    const columnsDetPag = React.useMemo<ColumnDef<IInvoiceDetail>[]>(
        () => [
            {
                accessorKey: 'dueDate',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {formatDateWithoutHours(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data vencimento
                    </TableCell>
                ),
            },
            {
                accessorKey: 'paymentDate',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {cell?.getValue()
                            ? formatDateWithoutHours(cell?.getValue())
                            : ''}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data pagamento
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Código / Número
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell align="left" key={row.id}>
                            {handleCodeOrProcessNumber(row.original)}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'description',
                cell: (cell: any) => (
                    <TableCell align="left">{cell?.getValue()}</TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Cliente
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell align="left" key={row.id}>
                            {handleCustomer(row.original)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Identificação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell key={row.id} align="left">
                            {handleIdentification(row.original)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Origem
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell key={row.id} align="left">
                            {handleOrigin(row.original)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Advogado
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell key={row.id} align="left">
                            {handleUser(row.original)}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'installmentValue',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {formatNumber(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <Tooltip
                            title="Dar baixa"
                            onClick={() => {
                                const objQuery = queryToObj(
                                    window.location.search.replace('?', ''),
                                );
                                const action = 'writeOff';
                                objQuery.action = action;
                                objQuery.invoiceId = row.original.id;

                                history.push(`?${objToQuery(objQuery)}`);
                            }}>
                            <IconButton disabled={row.original.downloaded}>
                                <IconComponent icon={icons.downloadDone} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Cancelar baixa"
                            onClick={() => {
                                const objQuery = queryToObj(
                                    window.location.search.replace('?', ''),
                                );
                                const action = 'unsubscribe';
                                objQuery.action = action;
                                objQuery.invoiceId = row.original.id;

                                history.push(`?${objToQuery(objQuery)}`);
                            }}>
                            <IconButton disabled={!row.original.downloaded}>
                                <IconComponent icon={icons.notInterested} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Imprimir"
                            onClick={() => {
                                setMessageDialogTitle(paramsModais.titlePrint);
                                setOpenModalDownloadReport(prevState => ({
                                    ...prevState,
                                    id: row.original.id,
                                    open: true,
                                }));
                            }}>
                            <IconButton disabled={!row.original.downloaded}>
                                <IconComponent icon={icons.print} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const handleConfirmeDownloadReport = async (confirm: boolean) => {
        if (confirm) {
            setOpenLoading(true);
            try {
                await downloadReport({
                    nameReport: 'recibo',
                    params: {
                        id: openModalDownloadReport.id,
                        typeOperation: getValues('typeOperation')?.id || 'all',
                    },
                });
                setOpenLoading(false);
            } catch (error: any) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messageResponse || error.message,
                });
            }
        }
        setMessageDialogTitle(undefined);
        setOpenModalDownloadReport({ open: false, id: undefined });
    };

    const handleConfirmAction = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalAction(false);
            let queruStr =
                window.location.search?.length > 0
                    ? window.location.search.replace('?', '')
                    : '';
            const queryObj = queryToObj(queruStr);
            handleRunLow({ id: queryObj.invoiceId, action: queryObj.action });
        } else {
            setOpenModalAction(false);
        }

        const queryStringUseInFind = parametersOfQuery(
            window.location.search.replace('?', ''),
        );
        history.push(`?${queryStringUseInFind}`);
    };

    const handleCloseModalAction = () => {
        setOpenModalAction(false);
        const queryStringUseInFind = parametersOfQuery(
            window.location.search.replace('?', ''),
        );
        history.push(`?${queryStringUseInFind}`);
    };

    const handleRunLow = async ({
        id,
        action,
    }: {
        id: string;
        action: 'unsubscribe' | 'writeOff';
    }) => {
        try {
            setOpenLoading(true);
            await api.patch(`invoice-detail/${id}/action/${action}`, {});
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            setDeleteRegister(true);

            if (action === 'writeOff') {
                setMessageDialogTitle(paramsModais.titlePrint);
                setOpenModalDownloadReport(prevState => ({
                    ...prevState,
                    id: id,
                    open: true,
                }));
            }
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const updateAutocompleteCustomer = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`customer?name=${description}`);
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeDownloaded = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCustomer(event.target.value);
        }

        if (event.type === 'click' || event.type === 'keydown') {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const onChangeCustomer = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCustomer(event.target.value);
        }

        if (event.type === 'click' || event.type === 'keydown') {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const onChangeInputMultiple = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        handleByOnKeyPressEnterNewQuery();
    };

    const QUERY_DEFAULT = `page=1&limit=${
        rowsPerPageOptions[0]
    }&dateInitial=${momentZoneToUnix(
        defaultValues.dateInitial,
    )}&dateFinal=${momentZoneToUnix(defaultValues.dateFinal)}&user=${
        user.sub
    }&typeOperation=received&downloaded=0`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'date',
            name: 'dateInitial',
            control: control,
            label: 'Data de início',
            handleChange: (data: any) => handleOnChange(),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'date',
            name: 'dateFinal',
            control: control,
            label: 'Data de fim',
            handleChange: (data: any) => handleOnChange(),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'text',
            name: 'processNumber',
            variant: 'standard',
            control: control,
            label: 'Número do processo',
            handleOnKeyPress,
            setValue,
        },
        {
            typeInput: 'autocomplete',
            name: 'customer',
            variant: 'standard',
            control: control,
            label: 'Cliente',
            handleOnKeyPress,
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: options,
            activeDebounce: true,
            handleChange: onChangeCustomer,
        },
        {
            typeInput: 'multiple',
            name: 'user',
            variant: 'standard',
            control: control,
            label: 'Advogado',
            handleOnKeyPress,
            options: optionsUsers,
            setValue: setValue,
            getValues: getValues,
            activeDebounce: true,
            handleChange: onChangeInputMultiple,
        },
        {
            typeInput: 'autocomplete',
            name: 'downloaded',
            variant: 'standard',
            control: control,
            loadingAutocomplete: false,
            setValue: setValue,
            label: 'Fatura baixada',
            options: yesOrNoOrBothEnum,
            handleOnKeyPress,
            handleChange: onChangeDownloaded,
        },
        {
            typeInput: 'autocomplete',
            name: 'typeOperation',
            variant: 'standard',
            control: control,
            loadingAutocomplete: false,
            setValue: setValue,
            label: 'Tipo',
            options: typeQueryOperationEnum,
            handleOnKeyPress,
            handleChange: onChangeDownloaded,
        },
        {
            typeInput: 'text',
            name: 'code',
            variant: 'standard',
            control: control,
            label: 'Código',
            handleOnKeyPress,
            setValue,
        },
    ];

    const handleOnChange = () => {
        handleByOnKeyPressEnterNewQuery();
    };

    return (
        <div className="principal-container-payment">
            <AlertDialog
                handleConfirmation={handleConfirmeDownloadReport}
                open={openModalDownloadReport.open}
                messageDialogTitle={messageDialogTitle}
            />
            <AlertDialog
                handleConfirmation={handleConfirmAction}
                open={openModalAction}
                messageDialogTitle={messageDialogTitle}
                handleCloseProps={handleCloseModalAction}
                messageDetail={messageDialogContent}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }} component="div">
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                {settings.title}
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={1}
                                direction="row"
                                width={'100%'}
                                sx={{
                                    alignItems: 'center',
                                }}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[0].name}
                                            control={inputsFilter[0].control}
                                            rules={null}
                                            label={inputsFilter[0].label}
                                            setValue={inputsFilter[0].setValue}
                                            fullWidth={
                                                inputsFilter[0].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[0].setError}
                                            clearErrors={
                                                inputsFilter[0].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[0].handleChange
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[1].name}
                                            control={inputsFilter[1].control}
                                            rules={null}
                                            label={inputsFilter[1].label}
                                            setValue={inputsFilter[1].setValue}
                                            fullWidth={
                                                inputsFilter[1].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[1].setError}
                                            clearErrors={
                                                inputsFilter[1].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[1].handleChange
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title="Gerar relatório"
                                    onClick={async () => {
                                        try {
                                            const params = queryToObj(
                                                window.location.search.replace(
                                                    '?',
                                                    '',
                                                ),
                                            );

                                            // Se não ser parcelas de entrada e saídas, não faz sentido ter o saldo, pois ele é calculado
                                            let nameReport = 'faturamento';
                                            if (
                                                params.typeOperation !== 'all'
                                            ) {
                                                nameReport = 'faturamento_user';
                                            }

                                            setLoadingDownloadReport(true);
                                            await downloadReport({
                                                nameReport,
                                                params: params,
                                            });
                                            setLoadingDownloadReport(false);
                                        } catch (error: any) {
                                            setLoadingDownloadReport(false);
                                            const messageResponse =
                                                handleExceptionMessage(error);
                                            addToast({
                                                type: 'error',
                                                title: message.error.selectAll,
                                                description:
                                                    messageResponse ||
                                                    error.message,
                                            });
                                        }
                                    }}>
                                    {loadingDownloadReport ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={24}
                                        />
                                    ) : (
                                        <IconButton>
                                            <IconComponent
                                                icon={icons.paidTwoTone}
                                            />
                                        </IconButton>
                                    )}
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>

                    <TableGeneric
                        data={rows}
                        columns={columnsDetPag}
                        minWidth={650}
                    />
                    <br />
                    <div className="info-footer">
                        <div className="footer-buttons"></div>
                        <Typography
                            id="total"
                            component="div"
                            sx={{ fontWeight: 'bold' }}>
                            {`Totais:`}
                            {legendTotal.received ? (
                                <Typography
                                    id="total"
                                    component="div"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.success,
                                    }}>
                                    {legendTotal.received}
                                </Typography>
                            ) : (
                                ''
                            )}
                            {legendTotal.toReceive ? (
                                <Typography
                                    id="total"
                                    component="div"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.info,
                                    }}>
                                    {legendTotal.toReceive}
                                </Typography>
                            ) : (
                                ''
                            )}
                            <Typography
                                id="total"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.error,
                                }}>
                                {legendTotal?.overdue}
                            </Typography>
                            <Typography
                                id="total"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                }}>
                                {legendTotal?.grandTotal}
                            </Typography>
                        </Typography>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            <FilterSearchComponent
                inputs={inputsFilter}
                setToggleSearch={setToggleSearch}
                toggleSearch={toggleSearch}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                page={page}
                implementationLoadRows={loadRows}
                updateRows={deleteRegister}
                setUpdateRows={setDeleteRegister}
                queryDefault={QUERY_DEFAULT}
            />
        </div>
    );
};

export default ListEntity;
