import { ModeEdit } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    TableCell,
    Tooltip,
    Typography,
} from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
    GenericEnum,
    indPagOptions,
    tPagOptions,
    yesOrNoOptions,
} from '../../communs/enums/generic-enum';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import { FormInputText } from '../../componets/form/formInputText';
import { FormInputProps } from '../../componets/form/formInterfaces';
import { IconComponent, icons } from '../../componets/icons';
import { TableGeneric } from '../../componets/table';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { colors } from '../../util/colors';
import { formatDateWithoutHours, momentZoneToDate } from '../../util/dateUtil';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    floatValue,
    formatNumber,
    IObjectKeys,
    roundNumber,
} from '../../util/infoFormat';
import { useWindowSize } from '../../util/responsiveness';
import { startOfDay } from 'date-fns';

interface IFormInput {
    formPayment: Description;
    typePayment: Description;
    installmentValue: number | null;
    numberInstallments: number;
    withEntrance: Description;
    valueEntry?: number | null;
    dateEntry?: Date | null;
    dueDate: number; // Dia vencimento
    total: number;
    invDetails: DetPagamento[];
    operation?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
    code?: string;
    processNumber?: string;
    customer?: {
        name: string;
    };
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    getValues: any;
    append: any;
    update: any;
    setIndex: (index: number) => void;
    fields: any[];
    width: number;
}

interface Description {
    id: string;
    description: string;
}

interface DetPagamento extends IObjectKeys {
    // Campos para NFe
    formPayment: Description;
    typePayment: Description;
    installmentValue: number | string;
    // Campos que não são utlizados na NFe
    description: string;
    dueDate: Date;
    legalProcess?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
    additionalHonorarium?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
    additionalExpense?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
}

interface InvDetailRequest {
    // Campos para NFe
    formPayment: string;
    typePayment: string;
    installmentValue: number | string;
    // Campos que não são utlizados na NFe
    description: string;
    dueDate: Date;
    legalProcess?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
    additionalHonorarium?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
    additionalExpense?: {
        id: string;
        code: string;
        customer: {
            name: string;
        };
    };
    downloaded?: boolean;
}

interface IParams {
    id: string;
    typeQuery: 'additionalHonorarium' | 'legalProcess' | 'additionalExpense';
    userId: string;
    callbackRedirect:
        | 'generatedInvoices'
        | 'invoiceDetailList'
        | 'legalProcess'
        | 'additionalHonorarium'
        | 'additionalExpense';
}

const defaultValues = {
    invDetails: [],
};

const indPagInstance = new GenericEnum(indPagOptions);
const indPagEnum = indPagInstance.optionsList();
const tPagInstance = new GenericEnum(tPagOptions);
const tPagEnum = tPagInstance.optionsList();
const yesOrNoInstance = new GenericEnum(yesOrNoOptions);
const yesOrNoEnum = yesOrNoInstance.optionsList();

const messageError = (errors: any, field: any) => {
    // description
    if (errors && errors.type === 'required' && field === 'description') {
        return 'O campo descrição é obrigátorio.';
    }

    // email
    if (errors && errors.type === 'required' && field === 'email') {
        return 'O campo e-mail é obrigátorio.';
    }
    if (errors && errors.type === 'pattern' && field === 'email') {
        return 'E-mail inválido.';
    }

    // identification
    if (errors && errors.type === 'validateCPF' && field === 'identification') {
        return 'CPF inválido.';
    }
    if (
        errors &&
        errors.type === 'validateCNPJ' &&
        field === 'identification'
    ) {
        return 'CNPJ inválido.';
    }
    return '';
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<IParams>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [indexItemDetPag, setIndexItemDetPag] = useState<number>(-1);
    const [openModalDetPag, setOpenModalDetPag] = useState(false);
    const [showValueEntry, setShowValueEntry] = useState(false);
    const [totalDetPag, setTotalDetPag] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [width] = useWindowSize();

    const columnsDetPag = React.useMemo<ColumnDef<DetPagamento>[]>(
        () => [
            {
                accessorKey: 'description',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleEditItemTableDetPag(cell.row.id)}>
                        {cell?.getValue()}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
            },
            {
                accessorFn: (row: any) => row.typePayment.description,
                id: 'typePayment',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleEditItemTableDetPag(cell.row.id)}>
                        {cell?.getValue()}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Forma de pagamento
                    </TableCell>
                ),
            },
            {
                accessorFn: (row: any) => row.dueDate,
                id: 'dueDate',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleEditItemTableDetPag(cell.row.id)}>
                        {formatDateWithoutHours(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data
                    </TableCell>
                ),
            },
            {
                accessorFn: (row: any) => row.installmentValue,
                id: 'installmentValue',
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() =>
                                handleEditItemTableDetPag(cell.row.id)
                            }>
                            {formatNumber(cell?.getValue())}
                        </TableCell>
                    );
                },
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton
                            onClick={() => handleEditItemTableDetPag(row.id)}>
                            <ModeEdit />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const [data, setData] = React.useState<DetPagamento[]>(() => []);

    const {
        control,
        reset,
        setValue,
        getValues,
        setError: setErrorDataPag,
        clearErrors: clearErrorsDataPag,
        handleSubmit,
    } = useForm<IFormInput>({
        defaultValues: {},
    });

    const getById = async (params: IParams) => {
        try {
            setOpenLoading(true);
            let pathConf = {
                operation: 'legal-process',
                fieldDetail: 'legalProcess.id',
                alisOperation: 'legalProcess',
            };
            if (params.typeQuery === 'additionalHonorarium') {
                pathConf = {
                    operation: 'additional-honorarium',
                    fieldDetail: 'additionalHonorarium.id',
                    alisOperation: 'additionalHonorarium',
                };
            }
            if (params.typeQuery === 'additionalExpense') {
                pathConf = {
                    operation: 'additional-expense',
                    fieldDetail: 'additionalExpense.id',
                    alisOperation: 'additionalExpense',
                };
            }
            const responseInvoiceDetails = await api.get(
                `invoice-detail?${pathConf.fieldDetail}=${params.id}&user=${params.userId}`,
            );
            const invoiceDetails = responseInvoiceDetails.data.data;

            if (invoiceDetails.length > 0) {
                setModelWithInvoiceDetails(
                    invoiceDetails[0][pathConf.alisOperation],
                    invoiceDetails,
                );
            } else {
                const response = await api.get(
                    `${pathConf.operation}/${params.id}`,
                );
                setModel(response.data);
            }
        } catch (error) {
            console.error(error);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.selectOne,
                description: messageResponse,
            });
        }
        setOpenLoading(false);
    };

    useEffect(() => {
        if (params && params.id && params.typeQuery) {
            getById(params);
        }
    }, [params]);

    useEffect(() => {
        if (getValues('invDetails')?.length > 0) {
            setData(getValues('invDetails'));
        }
    }, [getValues('invDetails')]);

    const {
        fields: fieldsDetPag,
        append: appendDetPag,
        update: updateDetPag,
    } = useFieldArray<IFormInput, 'invDetails', 'invDetailsId'>({
        control,
        name: 'invDetails',
        keyName: 'invDetailsId',
    });

    const onChangeWithEntrance = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option?.id === '0') {
            setShowValueEntry(false);
            setValue('valueEntry', null);
            setValue('dateEntry', null);
        }
        if (option?.id === '1') {
            setShowValueEntry(true);
            setValue('dateEntry', momentZoneToDate(undefined));
        }
    };

    const dataPag: FormInputProps<any>[] = [
        {
            typeInput: 'autocomplete',
            name: 'formPayment',
            control: control,
            label: 'Forma de pagamento',
            loadingAutocomplete: false,
            setValue: setValue,
            options: indPagEnum,
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'typePayment',
            control: control,
            label: 'Tipo de pagamento',
            loadingAutocomplete: false,
            setValue: setValue,
            options: tPagEnum,
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'withEntrance',
            control: control,
            label: 'Com entrada',
            loadingAutocomplete: false,
            setValue: setValue,
            handleChange: onChangeWithEntrance,
            options: yesOrNoEnum,
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'number',
            name: 'valueEntry',
            control: control,
            label: 'Valor entrada',
            md: 4,
            xs: 12,
            decimalScale: 2,
            show: showValueEntry,
        },
        {
            typeInput: 'date',
            name: 'dateEntry',
            control: control,
            label: 'Data entrada',
            setValue: setValue,
            md: 4,
            xs: 12,
            show: showValueEntry,
        },
        {
            typeInput: 'number',
            name: 'numberInstallments',
            control: control,
            label: 'Quantidade de parcelas',
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
            show: true,
        },
        {
            typeInput: 'number',
            name: 'dueDate',
            control: control,
            label: 'Dia vencimento',
            md: 4,
            xs: 12,
            decimalScale: 0,
            show: true,
        },
    ];

    const handleEditItemTableDetPag = (index: number): void => {
        setIndexItemDetPag(index);
        setOpenModalDetPag(true);
    };

    useEffect(() => {
        const totDetPag = fieldsDetPag.reduce((accumulator, item) => {
            const installmentValue = Number(item.installmentValue);
            return accumulator + installmentValue;
        }, 0);

        setTotalDetPag(totDetPag + totalPaid);
    }, [fieldsDetPag, totalPaid]);

    const plotCode = (data: any) => {
        let code = '';

        if (params.typeQuery === 'legalProcess') {
            code = `Processo ${data.processNumber}`;
        }

        if (params.typeQuery === 'additionalHonorarium') {
            const origins = data.itemHonoraries.map((item: any) =>
                item.originType.description.toLowerCase(),
            );
            code = `honorários originados de ${origins.join(',')}`;
        }

        if (params.typeQuery === 'additionalExpense') {
            const origins = data.itemExpenses.map((item: any) =>
                item.originExpenditure.description.toLowerCase(),
            );
            code = `despesas originadas de ${origins.join(',')}`;
        }

        return code;
    };

    const setModel = (data: IFormInput) => {
        const code = data.processNumber ? `${data.processNumber}` : data.code;

        const operationInfo: any = {
            code: `Honorários adicionais ${code}`,
            customer: data.customer,
        };

        if (params.typeQuery === 'legalProcess') {
            operationInfo.code = `Processo ${code}`;
        }

        if (params.typeQuery === 'additionalExpense') {
            operationInfo.code = `Despesas adicionais ${code}`;
        }

        setValue('customer', operationInfo.customer);
        setValue('code', plotCode(data));
        setValue('total', data?.total || 0);
    };

    const setModelWithInvoiceDetails = (
        data: IFormInput,
        invDetails: InvDetailRequest[],
    ) => {
        const invD: DetPagamento[] = invDetails
            .filter(i => !i.downloaded)
            .map(i => ({
                ...i,
                typePayment: tPagInstance.getObject(i.typePayment),
                formPayment: indPagInstance.getObject(i.formPayment),
            }));

        const tPaid = invDetails
            .filter(i => i.downloaded)
            .reduce((accumulator, currentValue) => {
                return accumulator + Number(currentValue.installmentValue);
            }, 0);

        if (invDetails.length > 0 && invD.length == 0) {
            addToast({
                type: 'info',
                title: 'Todas as parcelas já foram pagas',
                description: `Total pago: ${formatNumber(tPaid)}`,
            });
        }

        setValue('customer', data.customer);
        setValue('code', plotCode(data));
        setTotalPaid(tPaid);
        setValue('total', data?.total ? data.total : 0);
        setValue('invDetails', invD);
    };

    const submit = async (invDetails: DetPagamento[]) => {
        try {
            if (invDetails.length === 0) {
                addToast({
                    type: 'warn',
                    title: 'Nenhuma parcela foi gerada',
                    description:
                        'Para aplicar é preciso ao menos gerar uma parcela.',
                });
                return;
            }

            const invDetailsRequest: InvDetailRequest[] = invDetails.map(i => ({
                ...i,
                typePayment: i.typePayment.id,
                formPayment: i.formPayment.id,
                downloaded: false,
                dueDate: startOfDay(i.dueDate),
            }));

            setOpenLoading(true);
            await api.post('invoice-detail', {
                listInvoices: invDetailsRequest,
            });
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: 'Parcelas geradas com sucesso',
            });
            setOpenLoading(false);
            history.push(`${paths[params.callbackRedirect]}`);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`provider/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.provider}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handlePlotGenerator = (data: IFormInput) => {
        const plots: DetPagamento[] = [];

        // data = {
        //     formPayment: getValues('formPayment'),
        //     typePayment: getValues('typePayment'),
        //     numberInstallments: Number(getValues('numberInstallments')),
        //     withEntrance: getValues('withEntrance'),
        //     valueEntry: getValues('valueEntry'),
        //     installmentValue: getValues('valueEntry') || 0,
        //     dateEntry: getValues('dateEntry'),
        //     dueDate: getValues('dueDate'),
        //     total: floatValue(getValues('total')) - totalPaid,
        //     invDetails: [],
        // };
        data.numberInstallments = Number(data.numberInstallments);
        data.installmentValue = data.valueEntry || 0;
        data.total = floatValue(getValues('total')) - totalPaid;
        data.invDetails = [];

        let amountInstallments = data?.total;

        let error = false;
        if (!data.formPayment?.id) {
            setErrorDataPag('formPayment', {
                type: `custom_indPag`,
                message: 'O campo é obrigatório',
            });
            error = true;
        }

        if (!data.typePayment?.id) {
            setErrorDataPag('typePayment', {
                type: `custom_tpag`,
                message: 'O campo é obrigatório',
            });
            error = true;
        }

        if (data?.formPayment?.id === '1') {
            if (!data?.numberInstallments) {
                setErrorDataPag('numberInstallments', {
                    type: `custom_numberInstallments`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
            if (!data?.dueDate) {
                setErrorDataPag('dueDate', {
                    type: `custom_dueDate`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
            if (!data?.withEntrance) {
                setErrorDataPag('withEntrance', {
                    type: `custom_withEntrance`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
        }

        if (data?.formPayment?.id === '1' && data?.withEntrance?.id === '1') {
            if (!data?.valueEntry) {
                setErrorDataPag('valueEntry', {
                    type: `custom_valueEntry`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
        }

        if (error) {
            return;
        }

        let additionalField: any = {
            additionalHonorarium: {
                id: params.id,
            },
        };
        if (params.typeQuery === 'legalProcess') {
            additionalField = {
                legalProcess: {
                    id: params.id,
                },
            };
        }
        if (params.typeQuery === 'additionalExpense') {
            additionalField = {
                additionalExpense: {
                    id: params.id,
                },
            };
        }

        // Parcelado, mas com entrada
        if (
            data.formPayment.id === '1' &&
            data.withEntrance?.id === '1' &&
            data?.dateEntry
        ) {
            data.valueEntry = floatValue(data.valueEntry);
            plots.push({
                formPayment: data.formPayment,
                typePayment: data.typePayment,
                installmentValue: data.valueEntry || 0,
                description: 'Entrada',
                dueDate: data.dateEntry,
                ...additionalField,
            });
            amountInstallments = amountInstallments - Number(data.valueEntry);

            const valuePlot =
                amountInstallments / Number(data.numberInstallments);
            for (
                let index = 0;
                index < Number(data.numberInstallments);
                index++
            ) {
                const datePlot = moment()
                    .add(index + 1, 'months')
                    .set({ date: data.dueDate })
                    .toDate();
                plots.push({
                    formPayment: data.formPayment,
                    typePayment: data.typePayment,
                    installmentValue: roundNumber(valuePlot),
                    description: `Parcela ${index + 1}`,
                    dueDate: datePlot,
                    ...additionalField,
                });
            }
        }

        // Parcelado, mas sem entrada
        if (data.formPayment.id === '1' && data.withEntrance?.id !== '1') {
            const valuePlot =
                amountInstallments / Number(data.numberInstallments);
            for (
                let index = 0;
                index < Number(data.numberInstallments);
                index++
            ) {
                const datePlot = moment()
                    .add(index + 1, 'months')
                    .set({ date: data.dueDate })
                    .toDate();
                plots.push({
                    formPayment: data.formPayment,
                    typePayment: data.typePayment,
                    installmentValue: roundNumber(valuePlot),
                    description: `Parcela ${index + 1}`,
                    dueDate: datePlot,
                    ...additionalField,
                });
            }
        }

        if (data.formPayment.id === '0') {
            data.numberInstallments = 1;
            data.withEntrance = yesOrNoInstance.getObject('1');
            data.valueEntry = 0;
            data.dateEntry = null;
            data.dueDate = Number(moment().format('DD'));
            const datePlot = momentZoneToDate(undefined);
            plots.push({
                formPayment: data.formPayment,
                typePayment: data.typePayment,
                installmentValue: amountInstallments,
                description: `Parcela 1`,
                dueDate: datePlot,
                ...additionalField,
            });
        }

        let totalSale = plots.reduce((accumulator, item) => {
            return accumulator + Number(item.installmentValue);
        }, 0);

        if (totalSale > data.total || totalSale < data.total) {
            const difference = totalSale - data.total;

            const lastValue = roundNumber(
                Number(plots[plots.length - 1].installmentValue) - difference,
            );
            plots[plots.length - 1].installmentValue = lastValue;

            totalSale = plots.reduce((accumulator, item) => {
                return accumulator + Number(item.installmentValue);
            }, 0);
        }

        clearErrorsDataPag('formPayment');
        clearErrorsDataPag('typePayment');
        clearErrorsDataPag('dueDate');
        clearErrorsDataPag('valueEntry');
        clearErrorsDataPag('numberInstallments');
        clearErrorsDataPag('withEntrance');

        setValue('invDetails', plots);
        plots.splice(0, plots.length);
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form
                    onSubmit={handleSubmit(data => handlePlotGenerator(data))}>
                    <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                        {dataPag.map((field, index) => {
                            if (field.typeInput === 'number') {
                                return (
                                    <Tooltip title={field.label} arrow>
                                        <Grid
                                            key={index}
                                            item
                                            display={
                                                field?.show === false
                                                    ? 'none'
                                                    : undefined
                                            }
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageError}
                                                decimalScale={
                                                    field?.decimalScale || 0
                                                }
                                            />
                                        </Grid>
                                    </Tooltip>
                                );
                            }
                            if (field.typeInput === 'autocomplete') {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormAutocompleteInitialized
                                            name={field.name}
                                            readOnly={field?.readOnly}
                                            control={field.control}
                                            rules={field.rules}
                                            label={field.label}
                                            loading={
                                                field.loadingAutocomplete ||
                                                false
                                            }
                                            options={field.options || []}
                                            setValue={field.setValue}
                                            handleChange={field.handleChange}
                                            variant={field.variant}
                                            handleOnKeyPress={
                                                field.handleOnKeyPress
                                            }
                                        />
                                    </Grid>
                                );
                            }
                            if (field.typeInput === 'date') {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        display={
                                            field?.show === false
                                                ? 'none'
                                                : undefined
                                        }
                                        xs={field.xs}>
                                        <FormInputDateWithoutHour
                                            name={field.name}
                                            control={field.control}
                                            rules={field.rules}
                                            label={field.label}
                                            setValue={field.setValue}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                );
                            }
                            if (field.typeInput === 'text') {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            readOnly={field?.readOnly}
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageError}
                                            mask={field?.mask}
                                        />
                                    </Grid>
                                );
                            }
                            return <></>;
                        })}
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                    fontSize: '16px',
                                    pb: 1,
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Gerar parcelas'}
                                    typeButton={'submit'}
                                    icon={<IconComponent icon={icons.check} />}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableGeneric
                                data={data}
                                columns={columnsDetPag}
                                minWidth={650}
                            />
                        </Grid>
                    </Grid>
                    <br />
                </form>
                <div className="info-footer">
                    <div className="footer-buttons">
                        <FormButton
                            label={'Aplicar'}
                            typeButton={'submit'}
                            onClick={() => submit(getValues('invDetails'))}
                        />
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </div>
                    <Typography
                        id="total"
                        sx={{ textAlign: 'right', mt: 1, mb: 0, pb: 0 }}
                        component="div">
                        <Typography
                            id="total"
                            component="div"
                            sx={{
                                fontWeight: 'bold',
                            }}>
                            {`Total: ${formatNumber(getValues('total'))}`}
                        </Typography>
                        <Typography
                            id="total"
                            component="div"
                            sx={{
                                fontWeight: 'bold',
                                color: colors.error,
                            }}>
                            {`Total cobrado: ${formatNumber(totalDetPag)}`}
                        </Typography>
                        <Typography
                            id="total"
                            component="div"
                            sx={{
                                fontWeight: 'bold',
                                color: colors.success,
                            }}>
                            {`Pago até o momento: ${formatNumber(totalPaid)}`}
                        </Typography>
                        <Typography
                            id="total"
                            component="div"
                            sx={{
                                fontWeight: 'bold',
                                color: colors.info,
                            }}>
                            {`Valores correspondentes à: ${getValues('code')}`}
                        </Typography>
                        <Typography
                            id="total"
                            component="div"
                            sx={{
                                fontWeight: 'bold',
                                color: colors.info,
                            }}>
                            {getValues('customer.name')
                                ? `Cliente: ${getValues('customer.name')}`
                                : ''}
                        </Typography>
                    </Typography>
                </div>

                <ModalItemDetPag
                    open={openModalDetPag}
                    setOpen={setOpenModalDetPag}
                    index={indexItemDetPag}
                    setIndex={setIndexItemDetPag}
                    getValues={getValues}
                    append={appendDetPag}
                    update={updateDetPag}
                    fields={fieldsDetPag}
                    width={width}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalItemDetPag({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    fields,
    width,
}: ModalProps) {
    const { addToast } = useToast();

    const defaultValuesItems = {};

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const messageErrorItems = (errors: any, field: any) => {
        // valueOutput
        if (errors && errors.type === 'required' && field === 'itemValue') {
            return 'O campo valor de saída é obrigátorio.';
        }
        if (errors && errors.type === 'validate' && field === 'itemValue') {
            return 'O campo valor de saída tem que ser maior que 0,00.';
        }

        // quantity
        if (errors && errors.type === 'required' && field === 'quantity') {
            return 'O campo quantidade é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'quantity') {
            return 'O campo quantidade tem que ser maior que 0.';
        }

        return '';
    };

    const useFormItems = useForm<DetPagamento>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    const inputs: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlItems,
            label: 'Descrição',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'typePayment',
            control: controlItems,
            label: 'Forma de pagamento',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: tPagEnum,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'date',
            name: 'dueDate',
            control: controlItems,
            label: 'Data de vencimento',
            setValue: setValueItems,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'number',
            name: 'installmentValue',
            control: controlItems,
            label: 'Valor',
            md: 6,
            xs: 12,
            decimalScale: 2,
            rules: {
                required: true,
            },
        },
    ];

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index, open]);

    const setModel = (index: number) => {
        const detPag = getValues(`invDetails[${index}]`);
        setValueItems('description', detPag.description);
        setValueItems('dueDate', detPag.dueDate);
        setValueItems('typePayment', detPag.typePayment);
        setValueItems('formPayment', detPag.formPayment);
        setValueItems('installmentValue', detPag.installmentValue);
        if (detPag.additionalExpense) {
            setValueItems('additionalExpense', detPag.additionalExpense);
        }
        if (detPag.additionalHonorarium) {
            setValueItems('additionalHonorarium', detPag.additionalHonorarium);
        }
        if (detPag.legalProcess) {
            setValueItems('legalProcess', detPag.legalProcess);
        }
    };

    const handleClose = () => {
        setIndex && setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const submitItem = (data: DetPagamento) => {
        data.installmentValue = floatValue(data.installmentValue);

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            Alterar item
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <IconComponent icon={icons.close} />
                        </IconButton>
                    </div>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {inputs.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageErrorItems}
                                                decimalScale={
                                                    field.decimalScale
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDateWithoutHour
                                                name={field.name}
                                                control={controlItems}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={setValueItems}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageErrorItems}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <span />
                        <Stack sx={{ pt: 2 }} spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
export default Form;
