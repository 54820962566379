import { Backdrop, CircularProgress } from '@mui/material';
import React, { createContext, useCallback, useContext, useState } from 'react';
import io from 'socket.io-client';

import api from '../services/api';
import { useAuth } from './AuthContext';
import { urls } from '../config';

interface ContextData {
    setOpenLoading(open: boolean): void;
    handleOpenCollapseRecords(open: boolean): void;
    openCollapseRecords: boolean;
    setNotificationNextAudience(open: boolean): void;
    notificationNextAudience: boolean;
    handleOpenCollapseInvoicing(open: boolean): void;
    openCollapseInvoicing: boolean;
    handleOpenCollapsePermissions(open: boolean): void;
    openCollapsePermissions: boolean;
    handleOpenCollapseExpenditurePortions(open: boolean): void;
    openCollapseExpenditurePortions: boolean;
    listNotificationNextAudience: Data[];
    setListNotificationNextAudience: (data: Data[]) => void;
    listBirthdays: Data[];
    setListBirthdays: (data: Data[]) => void;
    setNotificationBirthDay(open: boolean): void;
    notificationBirthDay: boolean;
}

interface Data {
    id: string;
    body: any;
    view: boolean;
    active?: boolean;
    company?: {
        id: string;
        description: string;
    };
}

type Props = { children: React.ReactNode; message: any };

const ContextGlobal = createContext<ContextData>({} as ContextData);

// const ProviderGlobal: React.FC<Props> = ({ children, message }) => {
const ProviderGlobal: React.FC<Props> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const { user } = useAuth();
    const [message, setMessage] = useState<any>({});
    const [notificationNextAudience, setNotificationNextAudience] =
        useState(false);
    const [listNotificationNextAudience, setListNotificationNextAudience] =
        React.useState<Data[]>(() => {
            let items: any = localStorage.getItem('@GESSIS:nextAudience');
            if (items) {
                items = JSON.parse(items);
                return items;
            }
            return [];
        });
    const [listBirthdays, setListBirthdays] = React.useState<any[]>(() => {
        let items: any = localStorage.getItem('@GESSIS:birthday');
        if (items) {
            items = JSON.parse(items);
            return items;
        }
        return [];
    });
    const [notificationBirthDay, setNotificationBirthDay] = useState(false);
    const [openCollapseRecords, setOpenCollapseRecords] = useState(() => {
        const openCollapseRecords = localStorage.getItem(
            '@GESSIS:openCollapseRecords',
        );

        if (openCollapseRecords) {
            return true;
        }
        return false;
    });

    const [openCollapseInvoicing, setOpenCollapseInvoicing] = useState(() => {
        const openCollapseInvoicing = localStorage.getItem(
            '@GESSIS:openCollapseInvoicing',
        );

        if (openCollapseInvoicing) {
            return true;
        }
        return false;
    });

    const [openCollapsePermissions, setOpenCollapsePermissions] = useState(
        () => {
            const openCollapsePermissions = localStorage.getItem(
                '@GESSIS:openCollapsePermissions',
            );

            if (openCollapsePermissions) {
                return true;
            }
            return false;
        },
    );

    const [
        openCollapseExpenditurePortions,
        setOpenCollapseExpenditurePortions,
    ] = useState(() => {
        const openCollapseExpenditurePortions = localStorage.getItem(
            '@GESSIS:openCollapseExpenditurePortions',
        );

        if (openCollapseExpenditurePortions) {
            return true;
        }
        return false;
    });

    React.useEffect(() => {
        if (
            user?.sub &&
            message &&
            message.type === `nextAudience-${user.sub}`
        ) {
            loadNotificationNextAudience(false);
        }
        if (
            user?.sub &&
            message &&
            message.type === `nextAudience-${user.sub}-login`
        ) {
            loadNotificationNextAudience(true);
        }
        if (message && message.type === `birthday`) {
            loadBirthdayPerson();
        }
    }, [message, user]);

    const baseURL =
        process.env.REACT_APP_ENVIROMENT === 'local'
            ? urls.api.local
            : urls.api.prod;

    const [socket, setSocket] = useState<any>(null);
    React.useEffect(() => {
        let skt = null;
        if (!!user) {
            skt = io(baseURL, {
                transports: ['websocket', 'polling', 'flashsocket'],
                query: {
                    token: localStorage.getItem('@GESSIS:token'),
                },
            });
            skt.on('msgToClient', (msg: any) => {
                setMessage(msg);
            });
            setSocket(skt);
        }
        return () => (socket ? socket.disconnect() : null);
    }, [user]);

    const loadNotificationNextAudience = React.useCallback(
        async (login: boolean) => {
            try {
                if (user && localStorage.getItem('@GESSIS:token')) {
                    const response = await api.get(
                        `notification?user.id=${user.sub}&notification.notificationType=nextAudience,nextAudienceOneDay&notification.active=1`,
                    );
                    let { data } = response;
                    data = data.data;

                    // Condição para disparar notificação
                    let items: any = localStorage.getItem(
                        '@GESSIS:nextAudience',
                    );
                    if (items) {
                        items = JSON.parse(items);
                    }
                    setListNotificationNextAudience(data);
                    if (
                        data &&
                        data.filter((r: any) => !r.view).length > 0 &&
                        login
                    ) {
                        setNotificationNextAudience(true);
                    }
                    data.forEach((d: any) => {
                        d.token = localStorage.getItem('@GESSIS:token');
                    });
                    if (data.length > 0) {
                        localStorage.setItem(
                            '@GESSIS:nextAudience',
                            JSON.stringify(data),
                        );
                    } else if (localStorage.getItem('@GESSIS:nextAudience')) {
                        localStorage.setItem(
                            '@GESSIS:nextAudience',
                            JSON.stringify([]),
                        );
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        [user],
    );

    const loadBirthdayPerson = useCallback(async () => {
        try {
            if (localStorage.getItem('@GESSIS:token')) {
                const response = await api.get(
                    'notification?notification.notificationType=birthday&notification.active=1',
                );
                let { data } = response;
                data = data.data;

                // Condição para disparar notificação
                let items: any = localStorage.getItem('@GESSIS:birthday');
                if (items) {
                    items = JSON.parse(items);
                }
                setListBirthdays(data);
                if (data && data.filter((r: any) => !r.view).length > 0) {
                    setNotificationBirthDay(true);
                }

                data.forEach((d: any) => {
                    d.token = localStorage.getItem('@GESSIS:token');
                });
                if (data.length > 0) {
                    localStorage.setItem(
                        '@GESSIS:birthday',
                        JSON.stringify(data),
                    );
                } else if (localStorage.getItem('@GESSIS:birthday')) {
                    localStorage.setItem(
                        '@GESSIS:birthday',
                        JSON.stringify([]),
                    );
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [message]);

    const handleOpenCollapseRecords = (value: boolean) => {
        if (value) {
            localStorage.setItem('@GESSIS:openCollapseRecords', 'true');
            setOpenCollapseRecords(true);
        } else {
            localStorage.removeItem('@GESSIS:openCollapseRecords');
            setOpenCollapseRecords(false);
        }
    };

    const handleOpenCollapseInvoicing = (value: boolean) => {
        if (value) {
            localStorage.setItem('@GESSIS:openCollapseInvoicing', 'true');
            setOpenCollapseInvoicing(true);
        } else {
            localStorage.removeItem('@GESSIS:openCollapseInvoicing');
            setOpenCollapseInvoicing(false);
        }
    };

    const handleOpenCollapsePermissions = (value: boolean) => {
        if (value) {
            localStorage.setItem('@GESSIS:openCollapsePermissions', 'true');
            setOpenCollapsePermissions(true);
        } else {
            localStorage.removeItem('@GESSIS:openCollapsePermissions');
            setOpenCollapsePermissions(false);
        }
    };

    const handleOpenCollapseExpenditurePortions = (value: boolean) => {
        if (value) {
            localStorage.setItem(
                '@GESSIS:openCollapseExpenditurePortions',
                'true',
            );
            setOpenCollapseExpenditurePortions(true);
        } else {
            localStorage.removeItem('@GESSIS:openCollapseExpenditurePortions');
            setOpenCollapseExpenditurePortions(false);
        }
    };

    return (
        <ContextGlobal.Provider
            value={{
                setOpenLoading: setOpen,
                openCollapseRecords,
                handleOpenCollapseRecords,
                notificationNextAudience,
                setNotificationNextAudience,
                handleOpenCollapseInvoicing,
                openCollapseInvoicing,
                openCollapsePermissions,
                handleOpenCollapsePermissions,
                openCollapseExpenditurePortions,
                handleOpenCollapseExpenditurePortions,
                listNotificationNextAudience,
                setListNotificationNextAudience,
                listBirthdays,
                setListBirthdays,
                notificationBirthDay,
                setNotificationBirthDay,
            }}>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1300,
                }}
                open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {children}
        </ContextGlobal.Provider>
    );
};

function useContextGlobal(): ContextData {
    const context = useContext(ContextGlobal);
    if (!context) {
        throw new Error('useToast must be used within a ProviderGlobal');
    }
    return context;
}

export { ProviderGlobal, useContextGlobal };
