import { Grid, Paper, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { GenericEnum, typeUserOptions } from '../../communs/enums/generic-enum';
import { FormInputProps } from '../../componets/form/formInterfaces';
import RenderForm from '../../componets/form/renderForm';
import IDescription from '../../util/interfaces/description';
import { useAuth } from '../../context/AuthContext';

interface IFormInput {
    name: string;
    userName: string;
    email: string;
    typeUser: {
        description: string;
        id: string;
    };
    company: {
        description: string;
        id: string;
    };
    roles: {
        description: string;
        id: string;
    }[];
    active: boolean;
    verifiedEmail: boolean;
    password?: string;
    passwordConfirmation?: string;
}

interface IUpdateAutocomplete {
    id: string;
    keyField: 'role';
}

const defaultValues = {
    name: '',
    userName: '',
    email: '',
    typeUser: undefined,
    active: true,
    roles: [],
    company: undefined,
};

const typeUserInstance = new GenericEnum(typeUserOptions);
const typeUserEnum = typeUserInstance.optionsList();

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [optionsRole, setOptionsRole] = useState<
        { description: string; id: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [optionsCompany, setOptionsCompany] = useState([]);

    const { user } = useAuth();

    useEffect(() => {
        async function loadRolesByCompanyId(companyId: string) {
            const response = await api.get(`role?company.id=${companyId}`);
            setOptionsRole(response.data.data);
        }

        if (params && params.id) {
            setOpenLoading(true);
            api.get(`users/${params.id}`)
                .then(response => {
                    if (response.data.roles.length > 0) {
                        loadRolesByCompanyId(response.data.roles[0].company.id);
                    }
                    setModel(response.data);

                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
        api.get(`company`)
            .then(response => {
                setOptionsCompany(response.data.data);
            })
            .catch(error => {
                console.error(error);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messageResponse,
                });
            });
    }, []);

    const { handleSubmit, control, reset, setValue, watch, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const setModel = (data: any) => {
        setValue('name', data.name);
        setValue('userName', data.userName);
        setValue('email', data.email);
        setValue('typeUser', typeUserInstance.getObject(data.typeUser));
        if (data.roles.length > 0) {
            setValue('roles', data.roles);
            setValue('company', data.roles[0].company);
        }
        setValue('active', data.active);
        setValue('verifiedEmail', data.verifiedEmail);
    };

    const autocompleteSettings = {
        role: {
            query: (value: string) => `role?company.id=${value}`,
            setOptions: (options: []) => {
                const data = options.map((d: any) => {
                    return {
                        id: d.id,
                        description: d.description,
                    };
                });
                setOptionsRole(data);
            },
        },
    };

    const updateAutocomplete = async ({
        id,
        keyField,
    }: IUpdateAutocomplete) => {
        if (id && id.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                autocompleteSettings[keyField].query(id),
            );
            autocompleteSettings[keyField].setOptions(
                response.data.data.map((d: IDescription) => ({
                    id: d.id,
                    description: d.description,
                })),
            );
            setLoadingAutocomplete(false);
        }
    };

    const onChangeSetValues = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option) {
            let keyField: 'role' = 'role';

            updateAutocomplete({ id: option.id, keyField });
        }
    };

    const inputsForm: FormInputProps<IFormInput>[] = [
        {
            typeInput: 'text',
            name: 'name',
            control: control,
            label: 'Nome',
            md: 6,
            xs: 12,
            rules: {
                required: true,
                minLength: 5,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo nome é obrigatório',
                },
                {
                    type: 'minLength',
                    message: 'O campo nome tem que ter mais do que 5 letras',
                },
            ],
        },
        {
            typeInput: 'text',
            name: 'userName',
            control: control,
            label: 'Login',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'email',
            control: control,
            label: 'Email',
            md: 4,
            xs: 12,
            rules: {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo email é obrigatório',
                },
                {
                    type: 'pattern',
                    message: 'O email está inválido. Ex.: email@email.com',
                },
            ],
        },
        {
            typeInput: 'autocomplete',
            name: 'typeUser',
            control: control,
            label: 'Tipo de usuário',
            options: typeUserEnum,
            setValue: setValue,
            loadingAutocomplete: false,
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo tipo de usuário é obrigatório',
                },
            ],
        },
        {
            typeInput: 'autocomplete',
            name: 'company',
            control: control,
            label: 'Empresa',
            options: optionsCompany,
            setValue: setValue,
            loadingAutocomplete: loadingAutocomplete,
            md: 4,
            xs: 12,
            rules: {
                validate: (val: string) => {
                    if (
                        watch('typeUser')?.id !== 'admin' &&
                        (!val || val?.length === 0)
                    ) {
                        return 'O campo empresa é obrigatório';
                    }
                },
            },
            handleChange: onChangeSetValues,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'roles',
            control: control,
            label: 'Funções/Papéis do usuário',
            options: optionsRole,
            setValue: setValue,
            loadingAutocomplete: loadingAutocomplete,
            multiple: true,
            md: 12,
            xs: 12,
            rules: {
                validate: {
                    valideRequired: (val: any) => {
                        if (
                            watch('typeUser')?.id !== 'admin' &&
                            val?.length === 0
                        ) {
                            return false;
                        }
                        return true;
                    },
                },
            },
            messagesError: [
                {
                    type: 'valideRequired',
                    message: 'O campo funções é obrigatório',
                },
            ],
            activeDebounce: true,
        },
        {
            typeInput: 'password',
            name: 'password',
            control: control,
            label: 'Senha',
            md: 5,
            xs: 12,
            rules: {
                pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,15}$/,
            },
            messagesError: [
                {
                    type: 'pattern',
                    message:
                        'A senha tem que ter de 6 a 15 caracteres, ao menos uma letra e ao menos um número',
                },
            ],
        },
        {
            typeInput: 'password',
            name: 'passwordConfirmation',
            control: control,
            label: 'Confirmar senha',
            md: 4,
            xs: 12,
            rules: {
                validate: (val: string) => {
                    if (val && watch('password') != val) {
                        return 'As senhas não conferem';
                    }
                },
            },
        },
        {
            typeInput: 'checkbox',
            name: 'active',
            control: control,
            label: 'Ativo',
            md: 1,
            xs: 12,
        },
        {
            typeInput: 'checkbox',
            name: 'verifiedEmail',
            control: control,
            label: 'Email verificado',
            md: 2,
            xs: 12,
            show: user?.typeUser === 'admin',
        },
    ];

    const submit = async (data: any) => {
        try {
            setOpenLoading(true);
            const typeUserId = data.typeUser.id;
            delete data.typeUser;
            data.typeUser = typeUserId;

            if (params && params.id) {
                await api.patch(`users/${params.id}`, data);
            } else {
                await api.post('users', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.goBack();
        } catch (error) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.error(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`users/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.user}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <RenderForm inputsForm={inputsForm} />
                    </Grid>
                    <br />

                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
            </Paper>
        </div>
    );
};

export default Form;
