import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Modal,
    Paper,
    Stack,
    Typography,
    Button,
    TableCell,
    IconButton,
} from '@mui/material';
import React, { useEffect, useState, FocusEvent } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import axios, { AxiosResponse } from 'axios';
import { cepMask } from '../../componets/form/mask/cep';
import { phoneMask } from '../../componets/form/mask/phone';
import { FormInputProps } from '../../componets/form/formInterfaces';
import RenderForm from '../../componets/form/renderForm';
import { IconComponent, icons } from '../../componets/icons';
import { useWindowSize } from '../../util/responsiveness';
import { ColumnDef } from '@tanstack/react-table';

interface IFormInput {
    nameCompany: string;
    installmentMaturity: number;
    nextAudience: number;
    publicPlace: string;
    number: string;
    addressComplement: string;
    district?: {
        id: string;
        description: string;
        cityIbgeId?: string;
        cityName?: string;
        cityAutocomplete?: {
            id: string;
            description: string;
        };
        stateIbgeId?: string;
        state?: string;
        stateName?: string;
        stateAutocomplete?: {
            id: string;
            description: string;
        };
        cep?: string;
    };
    phoneDdd: string;
    phoneNumber: string;
    email: string;
    itemConfigurations: ItemConfiguration[];
}

interface ItemConfiguration {
    nextAudience: number;
    user?: {
        id: string;
    };
    id?: string;
}

interface Expanded {
    address: boolean;
}

interface DataApiViaCep {
    cep: string;
    logradouro?: string;
    complemento?: string;
    bairro?: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
    erro?: boolean;
}

interface ModalDistrictProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    useFormItems: UseFormReturn<IFormInput>;
    width: number;
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    setIndex: (index: number) => void;
    getValues: any;
    append: any;
    update: any;
    width: number;
    inputs: FormInputProps<ItemConfiguration>[];
    useFormItems: UseFormReturn<ItemConfiguration>;
}

const defaultValues = {
    nextAudience: undefined,
    installmentMaturity: undefined,
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [expanded, setExpaded] = useState<Expanded>({
        address: false,
    });
    const [optionsDistrict, setOptionsDistrict] = useState<
        { id: string; description: string }[]
    >([]);

    const [optionsState, setOptionsState] = useState<
        { id: string; description: string }[]
    >([]);
    const [openModalDistrict, setOpenModalDistrict] = useState(false);
    const [width] = useWindowSize();

    const [openModal, setOpenModal] = useState(false);
    const [indexItem, setIndexItem] = useState(-1);

    const [optionsCity, setOptionsCity] = useState<
        { id: string; description: string }[]
    >([]);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`configuration/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    const messageResponse = handleExceptionMessage(e);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                        description: messageResponse,
                    });
                });
        }
    }, []);

    useEffect(() => {
        if (optionsState.length === 0 && expanded.address) {
            updateState();

            if (getValues('district.cityIbgeId')) {
                api.get(
                    `district?district.cityIbgeId=${getValues(
                        'district.cityIbgeId',
                    )}`,
                ).then(response => {
                    setOptionsDistrict(response.data.data);
                });
            }

            if (getValues('district.stateIbgeId')) {
                updateCity('' + getValues('district.stateIbgeId'));
            }
        }
    }, [expanded.address]);

    const userForm = useForm<IFormInput>({
        defaultValues,
    });

    const { handleSubmit, control, reset, setValue, getValues } = userForm;

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'itemConfigurations',
        'itemConfigurationsId'
    >({
        control,
        name: 'itemConfigurations',
        keyName: 'itemConfigurationsId',
    });

    const userFormItem = useForm<ItemConfiguration>({
        defaultValues: {
            nextAudience: 1,
        },
    });
    const controlItems = userFormItem.control;

    const setModel = (data: IFormInput) => {
        setValue('publicPlace', data.publicPlace);
        setValue('number', data.number);
        setValue('addressComplement', data.addressComplement);
        setValue('district', data.district);

        setValue('district.state', data.district?.state);
        setValue('district.stateIbgeId', data.district?.stateIbgeId);
        if (data.district?.stateIbgeId) {
            setValue('district.stateAutocomplete', {
                id: `${data.district?.stateIbgeId}`,
                description: `${data.district.state} - ${data.district.stateName}`,
            });
        }
        setValue('district.cityName', data.district?.cityName);
        setValue('district.cityIbgeId', data.district?.cityIbgeId);
        setValue('district.cityAutocomplete', {
            id: data.district?.cityIbgeId || '',
            description: data.district?.cityName || '',
        });

        setValue(
            'district.cep',
            data.district?.cep && cepMask(data.district.cep, false),
        );
        setValue('phoneDdd', data.phoneDdd);
        setValue(
            'phoneNumber',
            data.phoneNumber && phoneMask(data.phoneNumber, false),
        );
        setValue('email', data.email);

        setValue('itemConfigurations', data.itemConfigurations);
    };

    const submit = async (data: any) => {
        try {
            setOpenLoading(true);

            delete data.stateAutocomplete;
            delete data.cityAutocomplete;

            if (data.cep) {
                data.cep = data.cep.replace(/\D/g, '');
            }

            if (data.phoneNumber) {
                data.phoneNumber = data.phoneNumber.replace(/\D/g, '');
            }

            if (params && params.id) {
                await api.patch(`configuration/${params.id}`, data);
            } else {
                await api.post('configuration', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.configuration}?`);
        } catch (error) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleOnBlurCep = async (event: FocusEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value) {
            value = value.replace(/\D/g, '');
        }

        if (!value) {
            return;
        }

        setOpenLoading(true);
        try {
            const response: AxiosResponse<DataApiViaCep> = await axios.get(
                `https://viacep.com.br/ws/${value}/json/`,
            );

            const data = response.data;

            if (data.erro) {
                addToast({
                    type: 'warn',
                    title: message.error.selectOne,
                    description:
                        'O CEP deve estar inválido, verifique e digite novamente',
                });
            }

            if (data.bairro) {
                // API que busca district pela descrição, se não achar no banco ja cadastra ele e devolve, agora se achar, apenas devolve o registro
                try {
                    const resp = await api.get(
                        `district/exist-district?description=${data.bairro}&cityIbgeId=${data.ibge}`,
                    );

                    setValue('district', {
                        id: resp.data.id,
                        description: resp.data.description,
                    });
                } catch (error) {
                    addToast({
                        type: 'error',
                        title: message.error.save,
                        description: 'Tente salvar o bairro manualmente',
                    });
                    console.error(error);
                }
            } else {
                try {
                    const response = await api.get(
                        `district?district.cityIbgeId=${data.ibge}`,
                    );
                    setOptionsDistrict(response.data.data);
                } catch (error) {
                    addToast({
                        type: 'error',
                        title: message.error.selectAll,
                        description: 'Não foi possível selecionar os bairros',
                    });
                    console.error(error);
                }
                setValue('district', undefined);
            }

            setValue('publicPlace', data?.logradouro || '');
            setValue('district.cityName', data.localidade);
            setValue('district.cityIbgeId', data.ibge);
            setValue('district.cityAutocomplete', {
                id: data.ibge,
                description: data.localidade,
            });

            const dataState = await findStateBySigla(data.uf);
            updateCity(dataState.id);
            setValue('district.state', data.uf);
            setValue('district.stateIbgeId', dataState.id);
            setValue('district.stateName', dataState.nome);
            setValue('district.stateAutocomplete', {
                id: dataState.id,
                description: `${data.uf} - ${dataState.nome}`,
            });
        } catch (error) {
            addToast({
                type: 'warn',
                title: message.error.selectOne,
                description:
                    'O CEP deve estar inválido, verifique e digite novamente',
            });
        }
        setOpenLoading(false);
    };

    const updateCity = async (idState: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idState}/municipios`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                  };
              })
            : [];
        setOptionsCity(data);
        return data;
    };

    const onChangeState = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const [uf, nome] = option.description.split(' - ');
            setValue('district.state', uf);
            setValue('district.stateName', nome);
            setValue('district.stateIbgeId', option.id);
            await updateCity(option.id);
        }
    };

    const onChangeCity = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const response = await api.get(
                `district?district.cityIbgeId=${option.id}`,
            );
            setOptionsDistrict(response.data.data);
            setValue('district.cityName', option.description);
            setValue('district.cityIbgeId', option.id);
        }
    };

    const inputsFormAccordion: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'district.cep',
            control: control,
            label: 'CEP',
            md: 2,
            xs: 12,
            toolTipPros: {
                title: 'Digite o CEP para que a busca do endereço possa ser feita',
                arrow: true,
            },
            mask: 'cep',
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo CEP é obrigatório',
                },
            ],
            handleOnBlur: handleOnBlurCep,
            setValue,
        },
        {
            typeInput: 'autocomplete',
            name: 'district.stateAutocomplete',
            control: control,
            label: 'Estado',
            md: 3,
            xs: 12,
            options: optionsState,
            loadingAutocomplete: false,
            setValue,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo estado é obrigatório',
                },
            ],
            handleChange: onChangeState,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'district.cityAutocomplete',
            control: control,
            label: 'Cidade',
            md: 3,
            xs: 12,
            options: optionsCity,
            loadingAutocomplete: false,
            setValue,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo cidade é obrigatório',
                },
            ],
            handleChange: onChangeCity,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'district',
            control: control,
            label: 'Bairro',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsDistrict,
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo bairro é obrigatório',
                },
            ],
            actionOnInput: {
                action: () => {
                    setOpenModalDistrict(true);
                },
                iconProps: {
                    icon: icons.addCircle,
                },
                textTooltip: 'Adicionar bairro',
            },
            activeDebounce: true,
        },
        {
            typeInput: 'text',
            name: 'publicPlace',
            control: control,
            label: 'Logradouro',
            md: 5,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo logradouro é obrigatório',
                },
            ],
        },
        {
            typeInput: 'text',
            name: 'addressComplement',
            control: control,
            label: 'Complemento',
            md: 5,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'number',
            control: control,
            label: 'Número',
            md: 2,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'email',
            control: control,
            label: 'Email',
            md: 5,
            xs: 12,
            rules: {
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
            messagesError: [
                {
                    type: 'pattern',
                    message: 'O email está inválido. Ex.: email@email.com',
                },
            ],
        },
        {
            typeInput: 'text',
            name: 'phoneDdd',
            control: control,
            label: 'DDD',
            md: 2,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'phoneNumber',
            control: control,
            label: 'Número telefone',
            md: 3,
            xs: 12,
            mask: 'phone',
        },
    ];

    const handleClick = (index: any) => {
        setIndexItem(index);
        setOpenModal(true);
    };
    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    const columnsTable = React.useMemo<ColumnDef<ItemConfiguration>[]>(
        () => [
            {
                id: 'user',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Antecedência de aviso para audiências
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.id)}>
                            {row.original?.nextAudience}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleClick(row.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                handleDeleteItemTable(row.id);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'subForm',
            name: 'subForm',
            control,
            label: 'Configuração por usuário',
            md: 12,
            xs: 12,
            dataTable: {
                propsTable: {
                    columns: columnsTable,
                    data: fields,
                    minWidth: 600,
                },
                handleNewItemTable: () => {
                    setOpenModal(true);
                    setIndexItem(-1);
                },
                inputs: [
                    {
                        typeInput: 'number',
                        name: 'nextAudience',
                        control: controlItems,
                        label: 'Antecedência de aviso para audiências',
                        md: 6,
                        xs: 12,
                        decimalScale: 0,
                        rules: {
                            validate: (value: number) => {
                                if (value && value < 0) {
                                    return 'O campo não pode ser menor que zero';
                                }
                            },
                        },
                    },
                ] as FormInputProps<ItemConfiguration>[],
            },
        },
    ];

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`configuration/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.configuration}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const updateState = async () => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: `${d.sigla} - ${d.nome}`,
                  };
              })
            : [];

        setOptionsState(data);
    };

    const findStateBySigla = async (sigla: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}`,
        );

        return {
            id: response.data.id,
            sigla: response.data.sigla,
            nome: response.data.nome,
        };
    };

    const handleChangeAccordionAddress =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                address: isExpanded,
            }));
        };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                {/* <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: 4,
                    }}>
                    <Tooltip
                        title={'Excluir registro'}
                        onClick={() => setOpenModalDelete(true)}>
                        <IconButton>
                            <IconComponent
                                icon={icons.delete}
                                color="warning"
                            />
                        </IconButton>
                    </Tooltip>
                </div> */}
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded.address}
                                onChange={handleChangeAccordionAddress()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Endereço</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <RenderForm
                                            inputsForm={inputsFormAccordion}
                                        />
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Typography
                                sx={{ mb: 2 }}
                                component="div"></Typography>
                            <RenderForm inputsForm={inputsForm} />
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
                <ModalDistrictType
                    open={openModalDistrict}
                    setOpen={setOpenModalDistrict}
                    width={width}
                    useFormItems={userForm}
                />
                <ModalItem
                    open={openModal}
                    setOpen={setOpenModal}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    width={width}
                    useFormItems={userFormItem}
                    inputs={
                        inputsForm.find(i => i.typeInput === 'subForm')
                            ?.dataTable?.inputs || []
                    }
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalDistrictType({
    open,
    setOpen,
    useFormItems,
    width,
}: ModalDistrictProps) {
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();

    const defaultValues = {
        description: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const useFormModal = useForm<any>({
        defaultValues: defaultValues,
    });
    const controlItems = useFormModal.control;
    const handleSubmit = useFormModal.handleSubmit;
    const reset = useFormModal.reset;

    const inputsForm: FormInputProps<any>[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlItems,
            label: 'Descrição',
            loadingAutocomplete: false,
            md: 12,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo descrição é obrigatório',
                },
            ],
            autoFocus: true,
        },
    ];

    const handleClose = () => {
        setOpen(false);
        reset(defaultValues);
    };

    const submitItem = async (data: any) => {
        try {
            if (
                !useFormItems.getValues('district.state') ||
                !useFormItems.getValues('district.cityName')
            ) {
                addToast({
                    type: 'warn',
                    title: 'Preencha o formulário na sequência',
                    description:
                        'Na seguinte ordem: estado, cidade e em seguida salve o novo bairro',
                });
                return;
            }

            setOpenLoading(true);
            const district = useFormItems.getValues('district');
            if (district) {
                data = {
                    ...data,
                    cityIbgeId: district.cityIbgeId,
                    cityName: district.cityName,
                    stateIbgeId: district.stateIbgeId,
                    state: district.state,
                    stateName: district.stateName,
                    cep: district.cep?.replace('-', ''),
                };
            }

            const response = await api.post('district', data);

            useFormItems.setValue('district', response.data);
            addToast({
                type: 'success',
                title: message.success.save,
                description: 'Bairro salvo',
            });
            handleClose();
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
        }
        setOpenLoading(false);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Adicionar bairro
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <RenderForm inputsForm={inputsForm} />
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar e aplicar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalItem({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    width,
    inputs,
    useFormItems,
}: ModalProps) {
    const defaultValuesItems = {
        nextAudience: 1,
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index, open]);

    const setModel = (index: number) => {
        setValueItems(
            'nextAudience',
            getValues(`itemConfigurations[${index}].nextAudience`),
        );
    };

    const handleClose = () => {
        setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const submitItem = (data: any) => {
        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir item
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <RenderForm inputsForm={inputs} />
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;
