import { Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormButton } from '../../componets/form/formButton';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    GenericEnum,
    stateLegalProcessOptions,
} from '../../communs/enums/generic-enum';
import { FormInputProps } from '../../componets/form/formInterfaces';
import IDescription from '../../util/interfaces/description';
import IName from '../../util/interfaces/name';
import { useAuth } from '../../context/AuthContext';
import RenderForm from '../../componets/form/renderForm';

interface IFormInput {
    code: string;
    dateHearing: Date | undefined;
    customer?: IDescription;
    user?: IDescription;
    situation?: {
        id: string;
        description: string;
    };
    processNumber: string;
    honoraryValue: number | string;
    description: string;
    generateInstallments?: boolean;
}

interface IUpdateAutocomplete {
    description: string;
    keyField: 'customer' | 'user';
}

const defaultValues = {
    dateHearing: undefined,
    honoraryValue: 0,
    generateInstallments: false,
};

const instanceStateOptions = new GenericEnum(stateLegalProcessOptions);
const stateOptions = instanceStateOptions.optionsList();

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [customerOptions, setCustomerOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [userOptions, setUserOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const { user } = useAuth();

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    useEffect(() => {
        if (!params?.id) {
            setTimeout(() => {
                setValue('honoraryValue', defaultValues.honoraryValue);
                setValue('dateHearing', defaultValues.dateHearing);
                setValue(
                    'generateInstallments',
                    !defaultValues.generateInstallments,
                );
            }, 100);
        }
    }, []);

    useEffect(() => {
        let queryAdd = '';
        if (user && !user.permissions['legal_process_execute']) {
            queryAdd = `?user.id=${user.sub}`;
        }

        if (!params?.id) {
            api.get(`users/${queryAdd}`)
                .then(response => {
                    setModel(response.data);
                    const users = response.data.data.map((u: any) => ({
                        id: u.id,
                        description: u.name,
                    }));

                    setUserOptions(users);
                    const findUser = users.find((u: IName) => u.id == user.sub);
                    setValue('user', findUser);

                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    const messageResponse = handleExceptionMessage(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                        description: messageResponse,
                    });
                });
        }

        if (params && params.id) {
            setOpenLoading(true);
            api.get(`legal-process/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    const messageResponse = handleExceptionMessage(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                        description: messageResponse,
                    });
                });
        }
    }, []);

    const autocompleteSettings = {
        customer: {
            query: (value: string) => `customer?name=${value}`,
            setOptions: (options: any[]) => {
                setCustomerOptions(options);
            },
        },
        user: {
            query: (value: string) => `users?name=${value}`,
            setOptions: (options: any[]) => {
                setUserOptions(options);
            },
        },
    };

    const updateAutocomplete = async ({
        description,
        keyField,
    }: IUpdateAutocomplete) => {
        if (description && description.length > 0) {
            try {
                setLoadingAutocomplete(true);
                const response = await api.get(
                    autocompleteSettings[keyField].query(description),
                );
                autocompleteSettings[keyField].setOptions(response.data.data);
            } catch (error) {
                const messagesResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: messagesResponse,
                });
            }
            setLoadingAutocomplete(false);
        }
    };

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            let keyField: 'customer' | 'user' = 'customer';
            if (event.target.id.includes('user')) {
                keyField = 'user';
            }

            updateAutocomplete({ description: event.target.value, keyField });
        }
    };

    const inputsForm: FormInputProps<IFormInput>[] = [
        {
            typeInput: 'component',
            name: 'code',
            control: control,
            label: 'Código',
            md: 12,
            xs: 12,
            componentProps: {
                component: (
                    <Typography
                        sx={{ flex: '1 1 100%', fontSize: '1rem' }}
                        variant="h6"
                        id="tableTitle"
                        component="div">
                        {getValues('code')
                            ? `Código: ${getValues('code')}`
                            : ''}
                    </Typography>
                ),
                conditionRender: params && params.id,
            },
        },
        {
            typeInput: 'text',
            name: 'processNumber',
            control: control,
            label: 'Número do processo',
            loadingAutocomplete: false,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo número do processo é obrigatório',
                },
            ],
        },
        {
            typeInput: 'autocomplete',
            name: 'customer',
            control: control,
            label: 'Cliente',
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: customerOptions,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo cliente é obrigatório',
                },
            ],
            handleChange: onChange,
            activeDebounce: true,
        },
        {
            typeInput: 'text',
            name: 'description',
            control: control,
            label: 'Descrição (opcional)',
            loadingAutocomplete: false,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'user',
            control: control,
            label: 'Advogado',
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            options: userOptions,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            messagesError: [
                {
                    type: 'required',
                    message: 'O campo Advogado(a) é obrigatório',
                },
            ],
            handleChange: onChange,
            activeDebounce: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'situation',
            control: control,
            label: 'Situação',
            loadingAutocomplete: false,
            setValue: setValue,
            options: stateOptions,
            md: 3,
            xs: 12,
            rules: {
                validate: (value: any) => {
                    if (!value?.id) {
                        return 'O campo situação é obrigatório';
                    }
                },
            },
            dto: data => {
                data.situation = data.situation.id;
                return data;
            },
        },
        {
            typeInput: 'date',
            name: 'dateHearing',
            control: control,
            label: 'Data audiência',
            setValue: setValue,
            md: 3,
            xs: 12,
            placeholder: 'dd/mm/aaaa hh:mm',
            rules: {
                validate: (value: any) => {
                    if (value && String(value) === 'Invalid Date') {
                        return 'A data está em um formato inválido';
                    }
                },
            },
        },
        {
            typeInput: 'number',
            name: 'honoraryValue',
            control: control,
            label: 'Valor de honorários',
            md: 3,
            xs: 12,
            decimalScale: 2,
            rules: {
                validate: (value: number) => {
                    if (value && value < 0) {
                        return 'O campo valor de honorários não pode ser menor que zero';
                    }
                },
            },
        },
        {
            typeInput: 'checkbox',
            name: 'generateInstallments',
            control: control,
            label: 'Gerar parcelas',
            md: 3,
            xs: 12,
        },
    ];

    const setModel = (data: any) => {
        setValue('code', data.code);
        setValue('processNumber', data.processNumber);
        if (data.customer && data.customer.id) {
            setValue('customer', {
                description: data.customer.name,
                id: data.customer.id,
            });
        }
        if (data.user && data.user.id) {
            setValue('user', {
                description: data.user.name,
                id: data.user.id,
            });
        }
        setValue('description', data.description);
        const situation = instanceStateOptions.getObject(data.situation);
        setValue('situation', situation);
        setValue('dateHearing', data.dateHearing);
        setValue('honoraryValue', data.honoraryValue);
    };

    const submit = async (data: any, e: any) => {
        e.preventDefault();
        try {
            setOpenLoading(true);

            for (const input of inputsForm) {
                if (!input?.dto) {
                    continue;
                }
                data = input.dto(data);
            }

            let response = { data: { id: null } };
            if (params && params.id) {
                response = await api.patch(`legal-process/${params.id}`, data);
            } else {
                response = await api.post('legal-process', data);
            }
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            reset(defaultValues);

            if (data.generateInstallments && data.honoraryValue > 0) {
                const redirect = Object.keys(paths).find(
                    k => k === 'legalProcess',
                );
                history.push(
                    `${paths.invoiceDetailRegister}/${response.data.id}/legalProcess/${data.user.id}/${redirect}`,
                );
            } else {
                history.push(`${paths.legalProcess}?`);
            }
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`legal-process/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.legalProcess}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(submit)}>
                    <Grid container spacing={2}>
                        <RenderForm inputsForm={inputsForm} />
                    </Grid>
                    <br />

                    <div className="info-footer">
                        <div className="footer-buttons">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            {params.id && (
                                <FormButton
                                    label={'Excluir'}
                                    typeButton={'delete'}
                                    onClick={() => setOpenModalDelete(true)}
                                />
                            )}
                            <FormButton
                                label={'Voltar'}
                                typeButton={'cancel'}
                                onClick={() => handleCancel()}
                            />
                        </div>
                    </div>
                </form>
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

export default Form;
