import MainContainer from '../../main-container';
import Form from './withdrawnForm';
import List from './withdrawnList';

const Withdrawn: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const WithdrawnList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Withdrawn, WithdrawnList };
