import React, { useCallback, useMemo, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultLabelDisplayedRows } from '../../../communs/tablePaginationAssistant';
import AlertDialog from '../../../componets/dialog/alertDialog';
import { FormButton } from '../../../componets/form/formButton';
import { FormInputDateWithoutHour } from '../../../componets/form/formInputDateWithoutHour';
import { paths, rowsPerPageOptions } from '../../../config';
import { useContextGlobal } from '../../../context/ContextGlobal';
import { useToast } from '../../../context/ToastContext';
import api from '../../../services/api';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
    unixToDate,
} from '../../../util/dateUtil';
import { handleExceptionMessage } from '../../../util/handleExceptionAxios';
import { message } from '../../../util/handleMessages';
import {
    handleChangePage,
    handleChangeRowsPerPage,
    objToQuery,
    queryToObj,
} from '../../../util/query';
import './styles.scss';
import { TableGeneric } from '../../../componets/table';
import { IconComponent, icons } from '../../../componets/icons';
import { ColumnDef } from '@tanstack/react-table';
import FilterSearchComponent from '../../../componets/filterComponent/filterSearch';
import { formatNumber } from '../../../util/infoFormat';
import { IFormInputPropsFilter } from '../../../componets/interfaces/formInputPropsFilter';

interface Data {
    totalReceive: number;
    withdrawn: number;
    month: string;
    dateWithdrawn: Date;
    id: string;
}

interface IDataQuery {
    dateInitial?: Date;
    dateFinal?: Date;
    month: string;
}

const defaultValues = {
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
    month: '',
};

const settings = {
    pathRegister: paths.withdrawnRegister,
    pathList: paths.withdrawn,
    apiPath: 'withdrawn',
    permissionAdmin: 'withdrawn',
    tittle: 'Fechamentos de caixas',
};

const ProductList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm<IDataQuery>({
        defaultValues,
    });

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            const response = await api.get(`withdrawn?${queryString}`);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: messageResponse,
            });
            setOpenLoading(false);
        }
    };

    const handleClick = (id: string) => {
        handleEdit(id);
    };

    const initialValuesMemo = useMemo(
        () => [
            {
                limit: rowsPerPageOptions[0],
                page: 1,
                dateInitial: momentZoneToUnix(getValues('dateInitial')),
                dateFinal: momentZoneToUnix(getValues('dateFinal')),
            },
        ],
        [],
    );

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        defaultFilter: boolean | undefined,
    ) => {
        if (getFieldState('dateInitial').invalid && getValues('dateInitial')) {
            return;
        }
        if (getFieldState('dateFinal').invalid && getValues('dateFinal')) {
            return;
        }

        const queryObj = queryToObj(search.replace('?', ''));

        const newQuery = {
            ...queryObj,
            'withdrawn.month': getValues('month'),
            dateInitial: getValues('dateInitial')
                ? momentZoneToUnix(getValues('dateInitial'))
                : null,
            dateFinal: getValues('dateFinal')
                ? momentZoneToUnix(getValues('dateFinal'))
                : null,
            page: 1,
        };
        const nQuery = objToQuery(newQuery);
        history.push(`?${nQuery}`);
        loadRows(nQuery);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery(false);
        }
    };

    const handleChangeInput = (data: any, input: string) => {
        handleByOnKeyPressEnterNewQuery(false);
    };

    const QUERY_DEFAULT = `page=1&limit=${
        rowsPerPageOptions[0]
    }&dateInitial=${momentZoneToUnix(
        defaultValues.dateInitial,
    )}&dateFinal=${momentZoneToUnix(defaultValues.dateFinal)}`;

    const inputsFilter: IFormInputPropsFilter[] = [
        {
            typeInput: 'date',
            name: 'dateInitial',
            control: control,
            label: 'Data de início',
            handleChange: (data: any) => handleChangeInput(data, 'dateInitial'),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'date',
            name: 'dateFinal',
            control: control,
            label: 'Data de fim',
            handleChange: (data: any) => handleChangeInput(data, 'dateFinal'),
            setValue,
            fullWidth: true,
            clearErrors,
            setError,
        },
        {
            typeInput: 'text',
            name: 'month',
            variant: 'standard',
            control: control,
            label: 'Mês e ano',
            handleOnKeyPress,
            setValue,
        },
    ];

    const handleEdit = (id: string) => {
        history.push(`${settings.pathRegister}/${id}`);
    };
    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`withdrawn/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const handleMonth = useCallback((original: Data) => {
        const [year, onlyMonth] = original.month.split('-');
        return `${onlyMonth}/${year}`;
    }, []);

    const columnsTable = React.useMemo<ColumnDef<Data>[]>(
        () => [
            {
                id: 'month',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Mês
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {handleMonth(row.original)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data da retirada
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {formatDateWithoutHours(row.original.dateWithdrawn)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Total disponível para retirada
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {formatNumber(row.original.totalReceive)}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Retirado
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() => handleClick(row.original.id)}>
                            {formatNumber(row.original.withdrawn)}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Restante
                    </TableCell>
                ),
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() => handleClick(cell.row.original.id)}>
                            {cell.row.original.totalReceive
                                ? formatNumber(
                                      cell.row.original.totalReceive -
                                          cell.row.original.withdrawn,
                                  )
                                : ''}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton onClick={() => handleEdit(row.original.id)}>
                            <IconComponent icon={icons.modeEdit} />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setIdDelete(row.original.id);
                                setOpenModalDelete(true);
                            }}>
                            <IconComponent icon={icons.delete} />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    return (
        <div className="principal-container-withdrawn">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                {settings.tittle}
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={1}
                                direction="row"
                                width={'100%'}
                                justifyContent="space-between"
                                alignItems="flex-end">
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[0].name}
                                            control={inputsFilter[0].control}
                                            rules={null}
                                            label={inputsFilter[0].label}
                                            setValue={inputsFilter[0].setValue}
                                            fullWidth={
                                                inputsFilter[0].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[0].setError}
                                            clearErrors={
                                                inputsFilter[0].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[0].handleChange
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name={inputsFilter[1].name}
                                            control={inputsFilter[1].control}
                                            rules={null}
                                            label={inputsFilter[1].label}
                                            setValue={inputsFilter[1].setValue}
                                            fullWidth={
                                                inputsFilter[1].fullWidth ||
                                                false
                                            }
                                            setError={inputsFilter[1].setError}
                                            clearErrors={
                                                inputsFilter[1].clearErrors
                                            }
                                            handleChange={
                                                inputsFilter[1].handleChange
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Tooltip
                                    title="Limpar filtro de datas"
                                    onClick={() => handleClearDates()}>
                                    <IconButton>
                                        <IconComponent icon={icons.clearAll} />
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() => history.push(settings.pathRegister)}
                        />
                    </Typography>
                    <TableGeneric
                        data={rows}
                        columns={columnsTable}
                        minWidth={650}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={(event: unknown, newPage: number) =>
                            handleChangePage(
                                event,
                                newPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            handleChangeRowsPerPage(
                                event,
                                setRowsPerPage,
                                setPage,
                                history,
                                loadRows,
                            )
                        }
                    />
                </Paper>
            </Box>

            <FilterSearchComponent
                inputs={inputsFilter}
                setToggleSearch={setToggleSearch}
                toggleSearch={toggleSearch}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                page={page}
                implementationLoadRows={loadRows}
                updateRows={deleteRegister}
                setUpdateRows={setDeleteRegister}
                queryDefault={QUERY_DEFAULT}
            />
        </div>
    );
};

export default ProductList;
